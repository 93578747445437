export const StepsModal = [
  {
    label: "Datas",
    action: "days",
  },
  {
    label: "Meses",
    action: "months",
  },
];
