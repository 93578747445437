import { BaSeButton, BaSeHeading5, BaSeIcon, BaSeParagraph } from "@base/react";
import ModalDefault from "../modals/modal-default";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../loading";
import "./index.scss";

const CardStatus = ({ data, idItem, callback, isFinished, showDuplicate = true }) => {
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDeletePlan = () => {
    if (callback) callback({ id: data.id, type: "delete" });
    setShowModal(false);
    setOpen(false);
  };

  useEffect(() => {
    setId(idItem);
  }, [idItem]);

  return (
    <>
      <ModalDefault
        callBackClose={closeModal}
        show={showModal}
        title="Atenção!"
      >
        <BaSeParagraph>
          Tem certeza que deseja excluir o planejamento?
        </BaSeParagraph>
        <div style={{ display: "flex", gap: 15, paddingTop: "10px" }}>
          <BaSeButton value="Sim" onClick={handleDeletePlan} />
          <BaSeButton value="Não" onClick={closeModal} />
        </div>
      </ModalDefault>
      <div className={`card-status ${data.isFinished ? "completed" : ""}`}>
        <div
          className="content"
          onClick={() => {
            if (open) setOpen(false);
          }}
        >
          <div className="title">
            <BaSeHeading5>{data.title}</BaSeHeading5>
            <p>
              ({data.isFinished ? "Concluído" : "Em andamento"} • Última edição:{" "}
              {moment(data.modifiedAt).format("DD/MM/YYYY")})
            </p>
          </div>

          {id === data.id && <Loading small />}
          <button
            className="open-options"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <BaSeIcon
              color="#005EB8"
              description="Menu"
              name="system/more-2-fill"
              size={24}
            />
          </button>
        </div>

        {id !== data.id && (
          <div className={`options ${open ? "open" : ""}`}>
            <ul>
              <li>
                {isFinished ? (
                  <Link to={`/planejamento/${data.id}/resultados`}>
                    <div className="link">
                      <BaSeIcon
                        color="#005EB8"
                        description="Menu"
                        name="eye"
                        size={24}
                      />
                      <span>Visualizar</span>
                    </div>
                  </Link>
                ) : (
                  <Link to={`/planejamento/${data.id}`}>
                    <div className="link">
                      <BaSeIcon
                        color="#005EB8"
                        description="Menu"
                        name="design/edit-2-line"
                        size={24}
                      />
                      <span>Editar</span>
                    </div>
                  </Link>
                )}
              </li>
              {
                showDuplicate ? (
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        if (callback) callback({ id: data.id, type: "duplicate" });
                        setOpen(false);
                      }}
                    >
                      <div className="link">
                        <BaSeIcon
                          color="#005EB8"
                          description="Menu"
                          name="copy"
                          size={24}
                        />
                        <span>Duplicar</span>
                      </div>
                    </button>
                  </li>
                ) : ""
              }
              <li>
                <button type="button" onClick={openModal}>
                  <div className="link">
                    <BaSeIcon
                      color="#005EB8"
                      description="Menu"
                      name="system/delete-bin-line"
                      size={24}
                    />
                    <span>Excluir</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default CardStatus;
