export function validateEmail(value) {
  let error;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
}
export function validateIsRequired(value) {
  return !value.trim();
}
export function validateCurrency(value) {
  return value < 0 || !/^\d{1,3}(\.\d{3})*,\d{2}$/.test(value);
}
export function validatePercentage(value, max = 100) {
  if (value) {
    value = parseFloat(value.toString().replace(",", ".").trim())
    return (value < 0 || value > max) || !/^[+-]?\d+(\.\d+)?$/.test(value);
  }
}
export function validateInteger(value, min = 0, max = 1000000) {
  value = parseInt(value)
  return value < min || value > max || !Number.isInteger(value);
}
