import "./index.scss";
import {
  BaSeButton,
  BaSeHeading3,
  BaSeHeading4,
  BaSeHeading5,
  BaSeHeading6,
  BaSeParagraph,
} from "@base/react";
import BoxPillPlanning from "../../components/box-pill-planning";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { principal } from "../../services/utils";
import Loading from "../../components/loading";
import ApiPlannings from "../../services/planning";
import ModalDefault from "../../components/modals/modal-default";
const apiPlannings = ApiPlannings();

const PillPlanning = ({ companies, account, setAccount }) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyCnpj, setCompanyCnpj] = useState("");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const blocksAreaRef = useRef(null);

  const handleMouseDown = (event) => {
    const area = blocksAreaRef.current;
    area.isDown = true;
    area.startX = event.pageX - area.offsetLeft;
    area.scrollLeft = area.scrollLeft;
  };
  const handleMouseLeave = () => {
    const area = blocksAreaRef.current;
    area.isDown = false;
  };
  const handleMouseUp = () => {
    const area = blocksAreaRef.current;
    area.isDown = false;
  };

  const handleMouseMove = (event) => {
    const area = blocksAreaRef.current;
    if (!area.isDown) return;
    event.preventDefault();
    const x = event.pageX - area.offsetLeft;
    const walk = (x - area.startX) * 0.1;
    area.scrollLeft = area.scrollLeft - walk;
  };

  const newPlan = () => {
    if (account?.activePlanning >= 120) {
      setShowModal(true);
    } else {
      navigate(`/planejamento`);
    }
  };

  const getPlannings = () => {
    setLoading(true);
    apiPlannings
      .getPlannings(principal(companies))
      .then((response) => {
        const { data } = response;

        const actualList = data.filter((item) => !item.in_trashcan);

        const plansThisYear = actualList.filter(
          (plan) => new Date(plan.created_at).getFullYear() === currentYear
        );
        setAccount({
          ...account,
          activePlanning: plansThisYear.length,
        });
      })
      .catch((err) => console.warn(err))
      .finally(() => setLoading(false));
  };

  const updateCompanyName = () => {
    if (companies.length) {
      companies.forEach((el) => {
        if (el.isActive && el.cnpj) {
          setCompanyName(el.name);
          setCompanyCnpj(el.cnpj);
        }
      });
    } else {
      setCompanyName("Pessoa Física");
      setCompanyCnpj("");
    }
  };

  useEffect(() => {
    getPlannings();
    updateCompanyName();
  }, []);

  useEffect(() => {
    updateCompanyName();
  }, [principal(companies)]);

  const [blocks] = useState([
    {
      id: "ID-4A7F9C3E",
      content: "Lucro",
      name: "lucro",
    },
    {
      id: "ID-B8E7D2A9",
      content: "Lucratividade",
      name: "lucratividade",
    },
    {
      id: "ID-C9D4E5B6",
      content: "Rentabilidade",
      name: "rentabilidade",
    },
    {
      id: "ID-5F8A6C1D",
      content: "Prazo de Retorno do Investimento",
      name: "prazo",
    },
    {
      id: "ID-7E3B9F2A",
      content: "Necessidade de formação de Capital de Giro",
      name: "necessidade",
    },
  ]);

  if (loading) {
    return <Loading fullPage />;
  }

  return (
    <div className="company-pill-area">
      <ModalDefault
        show={showModal}
        callBackClose={() => setShowModal(false)}
        square
        title="Limite Máximo de Planejamentos"
      >
        <div className="notes-modal">
          <BaSeHeading6>
            Você atingiu o limite máximo de <strong>120 planejamentos</strong>{" "}
            para o ano de {currentYear}.
          </BaSeHeading6>
          <br />
          <BaSeHeading6>
            Para adicionar novos planejamentos, por favor, exclua um
            planejamento já existente.
          </BaSeHeading6>
          <br />
          <BaSeButton value="Ok" onClick={() => setShowModal(false)} />
          {/* <div style={{ display: "flex", gap: 10 }}>
            <BaSeButton
              value="Meus Planejamentos"
              onClick={() => navigate("/meus-planejamentos")}
            />
          </div> */}
        </div>
      </ModalDefault>
      <BoxPillPlanning newPlan={newPlan}>
        <BaSeHeading3 color="#005EB8" fontFamily="Alegreya Sans" isBold>
          {companyName}
        </BaSeHeading3>
        <BaSeParagraph color="#8B99A7" fontFamily="Alegreya Sans">
          CNPJ: {companyCnpj}
        </BaSeParagraph>
        <span className="h4-pad">
          <BaSeHeading4 isBold>A Planejadora Sebrae</BaSeHeading4>
        </span>
        <BaSeParagraph>
          A Planejadora Financeira Empresarial Sebrae com foco em crédito foi
          especialmente estruturada para ajudar você, que deseja obter crédito
          para o seu negócio.
        </BaSeParagraph>
        <span className="h4-pad">
          <BaSeParagraph>
            Ou mesmo para você, empreendedor, que está iniciando a jornada
            empresarial e necessita de investimento para formalizar a sua
            empresa e colocar o seu plano de negócios para rodar.
          </BaSeParagraph>
        </span>
        <BaSeHeading5 isBold>O que posso calcular?</BaSeHeading5>
        <div
          className="blocks-area"
          ref={blocksAreaRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {blocks.map((e, index) => (
            <div key={e.id || index} className={`block ${e.name}`}>
              <BaSeParagraph isBold>{e.content}</BaSeParagraph>
            </div>
          ))}
        </div>
      </BoxPillPlanning>
    </div>
  );
};

export default PillPlanning;
