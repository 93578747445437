import React, {useCallback, useEffect, useRef, useState} from "react";
import NamePlanning from "./steps/name-planning";
import MonthlyAverage from "./steps/monthly-average";
import VariableIndirectCosts from "./steps/variable-indirect-costs";
import RouterPrompt from "../../components/router-prompt";
import TotalInvestment from "./steps/total-investment";
import ReceiptDeadline from "./steps/receipt-deadline";
import PaymentTerms from "./steps/payment-terms";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import InventoryTurnover from "./steps/inventory-turnover";
import ApiPlannings from "../../services/planning";
import {convertObjectValuesToFloat, convertToSlug, principal, sumValuesPercent,} from "../../services/utils";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../components/loading";
import ToastContent from "../../components/toastContent";
import {toast} from "react-toastify";
import iconSuccess from "../../assets/images/icons/check-circle.png";
import iconError from "../../assets/images/icons/exclamation-octagon.png";
import ProjectedCashFlow from "./steps/projected-cash-flow";
import Guarantees from "./steps/guarantees";
import Results from "./steps/results";
import Dialog from "../../components/dialog";
import {BaSeButton, BaSeIcon, BaSeParagraph} from "@base/react";
import ModalDefault from "../../components/modals/modal-default";
import PdfPage from "../../components/pdf-page";
import "./index.scss";

const apiPlannings = ApiPlannings();

const Planning = ({ companies }) => {
  const { idEdit, step } = useParams();
  const [hasToast, setHasToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idPlanning, setIdPlanning] = useState(null);
  const [currentPlanning, setCurrentPlanning] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentCnpj, setCurrentCnpj] = useState(null);
  const [dataPlanning, setDataPlanning] = useState(null);
  const [openDialogPrompt, setOpenDialogPrompt] = useState(false);
  const [openDialogFinish, setOpenDialogFinish] = useState(false);
  const [showModalOnSubmit, setShowModalOnSubmit] = useState(false);
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false)
  const touchStartY = useRef(0)
  const touchEndY = useRef(0)

  const checkHasChanges = (hasChanges) => {
    setOpenDialogPrompt(hasChanges);
  };

  useEffect(() => {
    if (step === "resultados" && dataPlanning && dataPlanning.results) {
      const current = steps().find(
        (item) => convertToSlug(item.title.toLowerCase().trim()) === step
      );
      setCurrentStep(current.index);
    }
    // eslint-disable-next-line
  }, [dataPlanning]);

  const handleOnSubmit = () => {
    if (!dataPlanning?.data?.goal_date) {
      setShowModalOnSubmit(true);
      return;
    }

    currentPlanning?.is_finished ? downloadPDF() : setOpenDialogFinish(true);
  };
  const formatDefaultForm = (response) => {
    const { data } = response;
    return {
      cnpj: data.cnpj,
      title: data.title,
      data: data.data,
      pdf_url: data.pdf_url,
      is_finished: data.is_finished,
      results: data.results,
    };
  };
  const finishPlanning = () => {
    setOpenDialogFinish(false);
    setLoading(true);

    apiPlannings
      .updatePlanning(
        {
          cnpj: principal(companies) || null,
          ...currentPlanning,
          data: {
            ...currentPlanning.data,
            lastStepSaved: 10,
          },
          is_finished: true,
        },
        idPlanning
      )
      .then((res) => {
        const { data } = res;
        setCurrentPlanning(formatDefaultForm(res));
        setDataPlanning(formatDefaultForm(res));
        setIdPlanning(data.id);
        if (!hasToast) {
          toast.success(
            <ToastContent title="Sucesso!" description="Planejamento salvo." />,
            {
              icon: () => <img src={iconSuccess} alt="icon" />,
              onOpen: () => setHasToast(true),
              onClose: () => setHasToast(false),
            }
          );
        }
        downloadPDF();
      })
      .catch((err) => {
        console.error("ERROR => ", err);
        toast.error(
          <ToastContent
            title="Falha!"
            description="Houve uma falha no sistema. Tente novamente."
          />,
          {
            icon: () => <img src={iconError} alt="icon" />,
          }
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const downloadPDF = async () => {
    const sections = document.querySelectorAll("#pdf-content section");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 10;
    const adjustedImage = pageWidth - 2 * margin;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/png");

      const imgHeight = (canvas.height * adjustedImage) / canvas.width;

      let yPosition = 0;

      while (yPosition < imgHeight) {
        pdf.addImage(
          imgData,
          "PNG",
          margin,
          -yPosition,
          adjustedImage,
          imgHeight
        );

        yPosition += pageHeight;

        if (yPosition < imgHeight) {
          pdf.addPage();
        }
      }

      if (i < sections.length - 1) {
        pdf.addPage();
      }
    }

    pdf.save("Relatorio.pdf");
  };
  const steps = useCallback(() => {
    const submitPlanning = (dataObj, callbackFinish) => {
      let lastStep;
      if (dataObj?.data?.lastStepSaved) {
        lastStep =
          dataObj?.data?.lastStepSaved > currentStep
            ? dataObj?.data?.lastStepSaved
            : currentStep;
      } else {
        lastStep = currentStep === 9 ? 10 : currentStep;
      }
      const data = {
        cnpj: principal(companies) || null,
        ...dataObj,
        data: {
          ...dataObj.data,
          lastStepSaved: lastStep,
        },
      };
      const nameStep = steps().find((item) => item.index === currentStep).title;
      if (currentPlanning) {
        apiPlannings
          .updatePlanning(data, idPlanning)
          .then((res) => {
            const { data } = res;
            setCurrentPlanning(formatDefaultForm(res));
            setDataPlanning(formatDefaultForm(res));
            setIdPlanning(data.id);

            if (!hasToast) {
              toast.success(
                <ToastContent
                  title="Sucesso!"
                  description={`Suas informações de <strong>${
                    currentStep === 1 ? "Nome do planejamento" : nameStep
                  }</strong> foram salvas.`}
                />,
                {
                  icon: () => <img src={iconSuccess} alt="icon" />,
                  onOpen: () => setHasToast(true),
                  onClose: () => setHasToast(false),
                }
              );
            }
            callbackFinish();
          })
          .catch((err) => {
            console.error("ERROR => ", err);
            const errorResponse = err.response?.data;
            let errorMessage = "Houve uma falha no sistema. Tente novamente.";

            if (errorResponse) {
              errorMessage =
                "Desculpe. Já existe outro planejamento com esse nome salvo.";
            }
            toast.error(
              <ToastContent title="Falha!" description={errorMessage} />,
              {
                icon: () => <img src={iconError} alt="icon" />,
              }
            );
            setLoading(false);
          });
      } else {
        apiPlannings
          .createPlanning(data)
          .then((res) => {
            const { data } = res;
            setCurrentPlanning(formatDefaultForm(res));
            setDataPlanning(formatDefaultForm(res));
            setIdPlanning(data.id);
            toast.success(
              <ToastContent
                title="Sucesso!"
                description="Planejamento criado."
              />,
              {
                icon: () => <img src={iconSuccess} alt="icon" />,
              }
            );
            callbackFinish();
          })
          .catch((err) => {
            console.error("ERROR => ", err);
            const errorResponse = err.response?.data;
            let errorMessage = "Houve uma falha no sistema. Tente novamente.";

            if (errorResponse) {
              errorMessage =
                "Desculpe. Já existe outro planejamento com esse nome salvo.";
            }
            toast.error(
              <ToastContent title="Falha!" description={errorMessage} />,
              {
                icon: () => <img src={iconError} alt="icon" />,
              }
            );
            setLoading(false);
          });
      }
    };
    const nextStep = () => {
      setCurrentStep(currentStep + 1);
      setLoading(false);
    };
    const updateDataPlanning = (
      value,
      goToNextStep = true,
      saveOnBlur = false,
      forceUpdate = false
    ) => {
      if (!saveOnBlur) setLoading(true);
      const newDataPlanning = { ...dataPlanning, ...value };
      setDataPlanning(newDataPlanning);
      const hasChanges =
        forceUpdate ||
        JSON.stringify(newDataPlanning) !== JSON.stringify(currentPlanning);

      if (currentPlanning?.is_finished) {
        nextStep();
      } else if (hasChanges) {
        submitPlanning(newDataPlanning, () => {
          goToNextStep ? nextStep() : setLoading(false);
        });
      } else {
        goToNextStep ? nextStep() : setLoading(false);
      }
    };
    const fnBackStep = (hasChanges) => {
      checkHasChanges(hasChanges);
      if (!hasChanges) {
        setCurrentStep(currentStep - 1);
      }
    };

    return [
      {
        index: 1,
        title: dataPlanning?.title ? dataPlanning?.title : "Novo Planejamento",
        content: (
          <NamePlanning
            dataPlanning={dataPlanning}
            sendForm={(e) => {
              updateDataPlanning(e);
            }}
          />
        ),
      },
      {
        index: 2,
        title: "Média Mensal",
        content: (
          <MonthlyAverage
            dataPlanning={dataPlanning}
            backStep={() => fnBackStep(false)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur, true);
            }}
          />
        ),
      },
      {
        index: 3,
        title: "Custos Indiretos Variáveis",
        content: (
          <VariableIndirectCosts
            dataPlanning={dataPlanning}
            backStep={() => fnBackStep(false)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur, true);
            }}
          />
        ),
      },
      {
        index: 4,
        title: "Investimento Total",
        content: (
          <TotalInvestment
            dataPlanning={dataPlanning}
            backStep={() => fnBackStep(false)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur, true);
            }}
          />
        ),
      },
      {
        index: 5,
        title: "Prazos de Recebimento",
        content: (
          <ReceiptDeadline
            dataPlanning={dataPlanning}
            backStep={(hasChanges) => fnBackStep(hasChanges)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur);
            }}
          />
        ),
      },
      {
        index: 6,
        title: "Prazos de Pagamento",
        content: (
          <PaymentTerms
            dataPlanning={dataPlanning}
            backStep={(hasChanges) => fnBackStep(hasChanges)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur);
            }}
          />
        ),
      },
      {
        index: 7,
        title: "Giro de Estoque",
        content: (
          <InventoryTurnover
            dataPlanning={dataPlanning}
            backStep={(hasChanges) => fnBackStep(hasChanges)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur);
            }}
          />
        ),
      },
      {
        index: 8,
        title: "Resultados",
        content: (
          <Results
            dataPlanning={dataPlanning}
            backStep={(hasChanges) => fnBackStep(hasChanges)}
            sendForm={(e, nextStep, fnBlur) => {
              updateDataPlanning(e, nextStep, fnBlur);
            }}
            downloadPlanning={() => downloadPDF()}
            openDialogFinish={() => setOpenDialogFinish(true)}
          />
        ),
      },
      {
        index: 9,
        title: "Fluxo de Caixa Projetado",
        content: (
          <ProjectedCashFlow
            dataPlanning={dataPlanning}
            backStep={(hasChanges) => fnBackStep(hasChanges)}
            sendForm={(e, forceUpdate) => {
              updateDataPlanning(e, false, false, forceUpdate);
            }}
            goNextStep={nextStep}
          />
        ),
      },
      {
        index: 10,
        title: "Garantias",
        content: (
          <Guarantees
            companies={companies}
            backStep={() => fnBackStep(false)}
          />
        ),
      },
    ];
    // eslint-disable-next-line
  }, [
    dataPlanning,
    companies,
    currentPlanning,
    currentStep,
    idPlanning,
    hasToast,
  ]);

  const forceUpdateLastStepSaved = (step, id, current) => {
    apiPlannings
      .updatePlanning(
        {
          cnpj: principal(companies) || null,
          ...current,
          data: {
            ...current?.data,
            lastStepSaved: step,
          },
        },
        id
      )
      .then((res) => {
        const { data } = res;
        setCurrentPlanning(formatDefaultForm(res));
        setDataPlanning(formatDefaultForm(res));
        setIdPlanning(data.id);
        // setCurrentStep(data?.data?.lastStepSaved);
      })
      .catch((err) => {
        console.error("ERROR => ", err);
        toast.error(
          <ToastContent
            title="Falha!"
            description="Houve uma falha no sistema. Tente novamente."
          />,
          {
            icon: () => <img src={iconError} alt="icon" />,
          }
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // swipeMobile
  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY
  }
  const handleTouchMove = (e) => {
    touchEndY.current = e.touches[0].clientY
  }
  const handleTouchEnd = () => {
    const deltaY = touchEndY.current - touchStartY.current
    if (deltaY > 30) {
      setIsExpanded(true)
    } else if (deltaY < -30) {
      setIsExpanded(false)
    }
  }
  const clickSetStep = (index) => {
    setCurrentStep(index)
    setIsExpanded(false)
  }

  useEffect(() => {
    if (idEdit) {
      setLoading(true);
      apiPlannings
        .getPlanning(idEdit)
        .then((res) => {
          const { data } = res;
          setCurrentPlanning(formatDefaultForm(res));
          setDataPlanning(formatDefaultForm(res));
          setIdPlanning(data.id);
          // setCurrentStep(data?.data?.lastStepSaved);
          if (data?.data?.lastStepSaved === undefined) {
            let stepSaved = 1;
            // checkSteps
            if (data?.data?.gde && Object.values(data?.data?.gde).length) {
              const values = convertObjectValuesToFloat(data?.data?.gde);
              const sum = sumValuesPercent(values) === 100;
              if (sum) stepSaved = 10;
            } else if (
              data?.data?.pdc &&
              Object.values(data?.data?.pdc).length
            ) {
              const values = convertObjectValuesToFloat(data?.data?.pdc);
              const sum = sumValuesPercent(values) === 100;
              if (sum) stepSaved = 6;
            } else if (
              data?.data?.pdr &&
              Object.values(data?.data?.pdr).length
            ) {
              const values = convertObjectValuesToFloat(data?.data?.pdr);
              const sum = sumValuesPercent(values) === 100;
              if (sum) stepSaved = 5;
            }
            forceUpdateLastStepSaved(
              stepSaved,
              data.id,
              formatDefaultForm(res)
            );
          }
        })
        .catch((data) => {
          console.error("ERROR => ", data);
          toast.error(
            <ToastContent
              title="Falha!"
              description="Houve uma falha no sistema. Tente novamente."
            />,
            {
              icon: () => <img src={iconError} alt="icon" />,
            }
          );
          if (data?.response) {
            const { status } = data?.response;
            if (status === 404) {
              setTimeout(() => {
                window.history.back();
              }, 2000);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [idEdit, step]);
  useEffect(() => {
    if (companies.length) {
      const activeCompany = companies.find((el) => el.isActive && el.cnpj);
      if (activeCompany) {
        if (currentCnpj && activeCompany.cnpj !== currentCnpj) {
          navigate("/meus-planejamentos");
        }
        setCurrentCnpj(activeCompany.cnpj);
      }
    }
  }, [companies, currentCnpj, navigate]);

  return (
    <div className="planning">
      <ModalDefault
        callBackClose={() => setShowModalOnSubmit(false)}
        show={showModalOnSubmit}
        title="Estabeleça sua meta!"
      >
        <BaSeParagraph>
          Para finalizar e baixar o planejamento, por favor preencha a Meta do
          planejamento na tela de <strong> Resultados.</strong>
        </BaSeParagraph>{" "}
        <br />
        <BaSeButton
          value="Ok"
          onClick={() => setShowModalOnSubmit(false)}
          width="71px"
        />
      </ModalDefault>

      <div className="steps">
        <div className={"steps__header"+(isExpanded ? " expanded" : "")}
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}>
          <div className="content_header">
            <ul>
              {steps().map((step) => {
                const active = step.index === currentStep ? "active" : "";
                const hasName =
                  step.index === 1 && currentStep > 1 ? "has-name" : "";
                const isCompleted =
                  step.index <= dataPlanning?.data?.lastStepSaved
                    ? "is-completed"
                    : "";
                return (
                  <li
                    key={step.index}
                    className={`${isCompleted} ${active} ${hasName}`}
                    onClick={
                      step.index <= dataPlanning?.data?.lastStepSaved
                        ? () => {
                          clickSetStep(step.index)
                        }
                        : undefined
                    }
                  >
                    <p>{step.title}</p>
                  </li>
                );
              })}
            </ul>
            <button
              onClick={handleOnSubmit}
              className={
                "btn-without-style finish-and-download " +
                (currentPlanning?.is_finished
                  ? "download"
                  : currentStep >= 8
                    ? "finish"
                    : "disabled")
              }
            >
              {currentPlanning?.is_finished ? (
                <BaSeIcon
                  color="#243342"
                  description="Download"
                  name="download-alt"
                  size={20}
                />
              ) : (
                ""
              )}
              <p>
                {!currentPlanning?.is_finished
                  ? "Finalizar e Baixar Planejamento"
                  : "Baixar Planejamento"}
              </p>
            </button>
          </div>
        </div>
        <div className="steps__body">
          {loading ? (
            <Loading />
          ) : (
            steps().map((step) =>
              step.index === currentStep ? (
                <div className="container" key={step.index}>
                  {step.content}
                </div>
              ) : (
                ""
              )
            )
          )}
        </div>
      </div>

      <RouterPrompt
        callbackCancel={() => setOpenDialogPrompt(false)}
        callbackConfirm={() => setCurrentStep(currentStep - 1)}
        forceShowDialog={openDialogPrompt}
      />
      <Dialog
        show={openDialogFinish}
        title="Atenção!"
        description={`
                Você está prestes a salvar este planejamento.<br/><br/>
                Após a confirmação, não será possível editá-lo. No entanto, este planejamento ficará disponível para
                consulta posterior e poderá ser duplicado para novos planejamentos com base nas informações atuais.<br/><br/>
                Deseja continuar?
              `}
        btnCancel="Voltar para edição"
        btnConfirm="Finalizar"
        onHide={() => setOpenDialogFinish(false)}
        callbackCancel={() => setOpenDialogFinish(false)}
        callbackConfirm={() => finishPlanning()}
      />

      {dataPlanning ? <PdfPage dataPlanning={dataPlanning} /> : ""}
    </div>
  );
};

export default Planning;
