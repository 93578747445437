import { BaSeParagraph } from "@base/react";
import "./index.scss";
import { BaSeIcon } from "@base/react";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>
        <BaSeIcon
          name="angle-left"
          color="#005eb8"
          description="Icon"
          size={20}
        />
      </button>
      <span>
        <BaSeParagraph color="#005eb8" isBold>
          {currentPage}
        </BaSeParagraph>
        <BaSeParagraph color="#005eb8">de</BaSeParagraph>
        <BaSeParagraph color="#005eb8">{totalPages}</BaSeParagraph>
      </span>
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        <BaSeIcon
          name="angle-right"
          color="#005eb8"
          description="Icon"
          size={20}
        />
      </button>
    </div>
  );
};

export default Pagination;
