import Requests from "./requests";

const baseUrl = process.env.REACT_APP_AMEI;
const realm = process.env.REACT_APP_REALM;
const client_id = process.env.REACT_APP_CLIENT_ID;
const redirect_uri = `${window.location.protocol}//${window.location.host}/`;

const ApiAuth = () => {
  const instance = Requests();
  const RegisterLogin = (device, browser) => {
    return instance.post(`/register-login/`, {
      device,
      browser,
    });
  };

  const GetCompanies = () => {
    return instance.get(`/get-cnpj/`);
  };

  const skipOnboarding = () => {
    return instance.get(`/onboarding/`);
  };
  const finishOnboarding = () => {
    return instance.post(`/onboarding/`);
  };

  const logout = () => {
    const logoutURI = new URL(
      `${baseUrl}/realms/${realm}/protocol/openid-connect/logout`
    );
    const id_token_hint = localStorage.getItem("id_token");
    const urlParams = {
      client_id,
      post_logout_redirect_uri: redirect_uri,
      id_token_hint,
    };
    for (const key of Object.keys(urlParams)) {
      logoutURI.searchParams.append(key, urlParams[key]);
    }
    localStorage.clear();
    window.location.href = logoutURI;
  };

  return {
    RegisterLogin,
    GetCompanies,
    skipOnboarding,
    finishOnboarding,
    logout,
  };
};

export default ApiAuth;
