import Requests from "./requests";

const ApiPlannings = () => {
  const instance = Requests();

  const createPlanning = (data) => {
    return instance.post(`/api/plans/`, data);
  };

  const getPlanning = (idPlanning) => {
    return instance.get(`/api/plans/${idPlanning}`);
  };

  const getPlannings = (cnpj) => {
    return instance.get(`/api/plans/`, {
      params: {
        cnpj,
      },
    });
  };

  const deletePlanning = (id) => {
    return instance.delete(`/api/plans/${id}/`);
  };

  const updatePlanning = (data, id) => {
    return instance.patch(`/api/plans/${id}/`, data);
  };

  const duplicatePlanning = (id, cnpj) => {
    return instance.get(`/api/plans/duplicate/${id}/`, {
      params: {
        cnpj,
      },
    });
  };

  return { createPlanning, getPlanning, getPlannings, deletePlanning, updatePlanning, duplicatePlanning };

};

export default ApiPlannings;
