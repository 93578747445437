import "./index.scss";
const TipBlock = ({ children, image, borderColor }) => {
  return (
    <div className="box" style={{ "--border-color": borderColor }}>
      <img src={image} />
      {children}
    </div>
  );
};
export default TipBlock;
