import { useCallback, useEffect, useState } from "react";
import {
  validateInteger,
  validatePercentage,
} from "../../../services/validate";
import {
  BaSeButton,
  BaSeGrid,
  BaSeGridItem,
  BaSeIcon,
  BaSeInput,
} from "@base/react";
import { Form, Formik } from "formik";
import TitleWithPopover from "../../../components/title-with-popover";
import RouterPrompt from "../../../components/router-prompt";
import {
  convertObjectValuesToFloat,
  formatCurrency,
  sortedArrayByObjectKeys,
  sumValuesPercent,
} from "../../../services/utils";

const InventoryTurnover = ({ dataPlanning, sendForm, backStep }) => {
  const structureData = (dataPlanning?.data?.gde &&
  dataPlanning?.data?.gde.length
    ? sortedArrayByObjectKeys(dataPlanning?.data?.gde).map((item) => {
        return {
          ...item,
          value: formatCurrency(item.value),
        };
      })
    : false) || [{ order: 0, days: 0, value: 0 }];
  const [form, setForm] = useState(structureData);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [hasNotRepeated, setHasNotRepeated] = useState(true);
  const checkValidForm = useCallback(() => {
    let invalid = false;
    if (!invalid) {
      invalid = sumValuesPercent(convertObjectValuesToFloat(form)) !== 100;
    }
    setIsInvalid(invalid);
  }, [form]);

  const hasDuplicates = (array) => {
    const seen = new Set();
    for (const value of array) {
      if (value !== 0 && seen.has(value)) {
        return true;
      }
      seen.add(value);
    }
    return false;
  };

  const handleChange = (order, name, newValue) => {
    let value = newValue;
    if (name === "days") {
      value = parseInt(value) || 0;
    }

    const updatedForm = form.map((field) =>
      field.order === order ? { ...field, [name]: value } : field
    );

    setForm(updatedForm);

    const daysValues = updatedForm.map((field) => field.days);
    setHasNotRepeated(!hasDuplicates(daysValues));
  };
  const addField = () => {
    setForm((prevFields) => [
      ...prevFields,
      {
        order:
          prevFields.length > 0
            ? prevFields[prevFields.length - 1].order + 1
            : 1,
        days: 0,
        value: 0,
      },
    ]);
  };
  const removeField = (index) => {
    setForm((prevFields) => {
      const updatedFields = prevFields.filter((field) => field.order !== index);
      return updatedFields.map((field, index) => ({
        ...field,
        order: index + 1,
      }));
    });
  };
  const fnOnBlur = () => {
    const data = convertObjectValuesToFloat(form);
    const allDaysGreaterThanZero = form.every((item) => item.days > 0);

    if (!allDaysGreaterThanZero) {
      return;
    }

    if (sumValuesPercent(data) === 100 && hasNotRepeated) {
      submitForm(false, true);
    }
  };
  const submitForm = (nextStep = true, fnBlur = false) => {
    const data = convertObjectValuesToFloat(form);
    if (!data.some((item) => item.days > 500)) {
      sendForm(
        {
          ...dataPlanning,
          data: {
            ...dataPlanning.data,
            gde: data,
          },
        },
        nextStep,
        fnBlur
      );
      setHasUnsavedChanges(false);
    }
  };

  useEffect(() => {
    checkValidForm();
  });

  function handlePercentageChangeForValue(e, handleChange, order, name) {
    let numericValue = parseFloat(e.replace(",", "."));

    if (isNaN(numericValue)) numericValue = 0;

    if (numericValue > 100) numericValue = 100;

    const formattedValue = numericValue.toFixed(2).replace(".", ",");

    handleChange(order, name, formattedValue);
  }

  return (
    <div className="step inventory-turnover">
      <TitleWithPopover
        title="Giro de Estoque"
        messagePopover={[
          "Indica o tempo médio que as mercadorias permanecem no seu estoque. Você pode estimar esse valor pelo intervalo entre cada compra de mercadoria:",
          "\n\ni. Compras diárias: estoque fica 1 dia parado",
          "\nii. Compras semanais: estoque fica, em média, 3,5 dias parado",
          "\niii. Compras mensais: estoque fica, em média, 15 dias parado",
          '\n\nCaso ainda não tenha essas informações, insira uma média geral do tempo que as mercadorias ficam no estoque, e no campo "% das compras", preencha com 100%.',
        ]}
        size={5}
      />

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm();
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {form.map(({ order, days, value }) => {
              return (
                <div key={order} className="grid-buttons-area">
                  <BaSeGrid>
                    <BaSeGridItem size={5}>
                      <BaSeInput
                        isDisabled={dataPlanning?.is_finished}
                        inputMode="tel"
                        typeInput="tel"
                        maxLength={3}
                        inputStatus={
                          validateInteger(days, 0, 500) || !hasNotRepeated
                            ? "invalid"
                            : "valid"
                        }
                        value={days}
                        onChange={(e) => {
                          handleChange(order, "days", e);
                        }}
                        onBlur={() => fnOnBlur()}
                        size="medium"
                        placeholder=""
                        mask={Number}
                        label="Dias"
                        width="100%"
                      />
                    </BaSeGridItem>
                    <BaSeGridItem size={5}>
                      <div className="top-input">
                        <BaSeInput
                          isDisabled={dataPlanning?.is_finished}
                          inputMode="decimal"
                          typeInput="tel"
                          prefix="% "
                          maxLength={8}
                          inputStatus={
                            validatePercentage(value) ? "invalid" : "valid"
                          }
                          value={value}
                          onChange={(e) => {
                            handleChange(order, "value", e);
                            handlePercentageChangeForValue(
                              e,
                              handleChange,
                              order,
                              "value"
                            );
                          }}
                          onBlur={() => fnOnBlur()}
                          size="medium"
                          placeholder="%"
                          label="% das compras"
                          width="100%"
                          mask="decimal"
                        />
                      </div>
                    </BaSeGridItem>
                    <BaSeGridItem size={1}>
                      <div className="remove-button">
                        {!dataPlanning?.is_finished && (
                          <button
                            type="button"
                            className="btn-without-style btn-add-more"
                            onClick={() => removeField(order)}
                            disabled={form.length === 1}
                            style={{
                              cursor: form.length !== 1 ? "pointer" : "no-drop",
                            }}
                          >
                            <BaSeIcon
                              color={form.length !== 1 ? "#005EB8" : "#C0C0C0"}
                              description="Adicionar"
                              name="minus-square"
                              size={24}
                            />
                          </button>
                        )}
                      </div>
                    </BaSeGridItem>
                  </BaSeGrid>
                </div>
              );
            })}
            <div className="result">
              <BaSeGrid>
                <BaSeGridItem size={5}>
                  <p>
                    Atenção: a soma dos percentuais lançados DEVE SER IGUAL a
                    100%.
                  </p>
                </BaSeGridItem>
                <BaSeGridItem size={5}>
                  <div
                    className={`base-input-container ${
                      sumValuesPercent(convertObjectValuesToFloat(form)) <= 100
                        ? "input-100"
                        : "input-not-100"
                    }`}
                  >
                    <BaSeInput
                      inputMode="decimal"
                      typeInput="tel"
                      suffix="%"
                      value={formatCurrency(
                        sumValuesPercent(convertObjectValuesToFloat(form))
                      )}
                      size="medium"
                      placeholder="%"
                      width="100%"
                      mask="decimal"
                      isDisabled={true}
                    />
                  </div>
                </BaSeGridItem>
              </BaSeGrid>
            </div>

            {form.length <= 500 && !dataPlanning?.is_finished ? (
              <button
                type="button"
                className="btn-without-style btn-add-more"
                onClick={addField}
              >
                <BaSeIcon
                  color="#005EB8"
                  description="Adicionar"
                  name="plus-square"
                  size={24}
                />
              </button>
            ) : (
              ""
            )}

            {!hasNotRepeated && (
              <p style={{ color: "red" }}>
                Existem valores duplicados nos campos de "Dias". Corrija para
                continuar.
              </p>
            )}

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep(hasUnsavedChanges)}
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid || !hasNotRepeated}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>

      <RouterPrompt
        initialValue={structureData}
        newValue={form}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
    </div>
  );
};

export default InventoryTurnover;
