import {BaSeHeading4, BaSeHeading5, BaSeHeading6, BaSePopupButton} from "@base/react";
import './index.scss'

const TitleWithPopover = ({title, smallTitle, titlePopover, messagePopover, size}) => {
  const TemplateSize = () => {
    const text = <>{title} <small className="small-title">{smallTitle}</small></>
    let layout = (<BaSeHeading6 color="#005EB8" fontFamily="Alegreya Sans" isBold>
      {text}
    </BaSeHeading6>)
    if (size === 5) {
      layout = (<BaSeHeading5 color="#005EB8" fontFamily="Alegreya Sans" isBold>
        {text}
      </BaSeHeading5>)
    } else if (size === 4) {
      layout = (<BaSeHeading4 color="#005EB8" fontFamily="Alegreya Sans" isBold>
        {text}
      </BaSeHeading4>)
    }

    return layout
  }
  return (
    <div className="title-with-popover">
      <TemplateSize />
      {
        messagePopover ? (<BaSePopupButton
          alertSize={180}
          direction="bottom"
          iconProps={{
            size: 16
          }}
          message={messagePopover}
          title={titlePopover || title}
          type="popover"
        />) : ""
      }
    </div>
  )
}

export default TitleWithPopover