import { useEffect, useState } from "react";
import {
  BaSeButton,
  BaSeDatePicker,
  BaSeHeading3,
  BaSeHeading5,
  BaSeHeading6,
  BaSeIcon,
  BaSeInput,
  BaSeParagraph,
} from "@base/react";
import "./index.scss";
import CardStatus from "../../components/card-status";
import Accordion from "../../components/accordion";
import BoxPillPlanning from "../../components/box-pill-planning";
import ApiPlannings from "../../services/planning";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import CardTrash from "../../components/card-trash";
import ModalDefault from "../../components/modals/modal-default";
import { converterSnakeToCamelCase, principal } from "../../services/utils";
import {toast} from "react-toastify";
import ToastContent from "../../components/toastContent";
import iconError from "../../assets/images/icons/exclamation-octagon.png";
const apiPlannings = ApiPlannings();

const count = 4;
const StepComponent = ({ filterActive, filterType }) => {
  const steps = [
    {
      label: "Todos",
      action: "clear",
    },
    {
      label: "Concluídos",
      action: "progress",
    },
    {
      label: "Em andamento",
      action: "completed",
    },
  ];
  return (
    <div className="step">
      {steps.map((step, index) => (
        <button
          key={index}
          type="button"
          className={filterActive === index ? "active" : ""}
          onClick={() => filterType(step.action, index)}
        >
          {step.label}
        </button>
      ))}
    </div>
  );
};

const MyPlanning = ({ companies, account, setAccount }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [listPlanning, setListPlanning] = useState([]);
  const [list, setList] = useState([]);
  const [updateTrash, setUpdateTrash] = useState(false);
  const [listTrash, setListTrash] = useState([]);
  const [filterActive, setFilterActive] = useState(0);
  const [planningToShow, setPlanningToShow] = useState(count);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyCnpj, setCompanyCnpj] = useState("");

  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const filterType = (type, active) => {
    setFilterActive(active);

    if (type === "clear") {
      setListPlanning(list);
    } else {
      const listFilter = list.filter((item) =>
        type === "progress" ? item.isFinished : !item.isFinished
      );
      setListPlanning(listFilter);
    }

    setPlanningToShow(count);
  };

  const getPlannings = (isDelete = false) => {
    apiPlannings
      .getPlannings(principal(companies))
      .then((response) => {
        const { data } = response;

        const [actualList, trashList] = data.reduce(
          ([actual, trash], item) => {
            item.in_trashcan
              ? trash.push(converterSnakeToCamelCase(item))
              : actual.push(converterSnakeToCamelCase(item));
            return [actual, trash];
          },
          [[], []]
        );

        setListPlanning(actualList);
        setList(actualList);
        setListTrash(trashList);

        const plansThisYear = actualList.filter(
          (plan) => new Date(plan.createdAt).getFullYear() === currentYear
        );
        setAccount({
          ...account,
          activePlanning: plansThisYear.length,
        });
      })
      .catch((err) => {
        console.warn("ERROR => ", err);
      })
      .finally(() => {
        setId("");
        setLoading(false);
        if (isDelete) {
          setUpdateTrash(true);
          setTimeout(() => {
            setUpdateTrash(false);
          }, 1000);
        }
      });
  };

  const duplicatePlannings = (id) => {
    setId(id);
    apiPlannings
      .duplicatePlanning(id, principal(companies))
      .then(() => {
        getPlannings();
      })
      .catch((err) => {
      if (err.status === 409) {
        toast.error(
          <ToastContent
            title="Não é possível duplicar"
            description={"Você atingiu o limite máximo de <strong>120 planejamentos</strong>."}
          />,
          {
            icon: () => <img src={iconError} alt="icon" />,
          },
        )
        }
        console.warn("ERROR => ", err);
      })
  }

  const deletePlanning = (id) => {
    setId(id);
    apiPlannings
      .deletePlanning(id)
      .then(() => {
        getPlannings(true);
      })
      .catch((err) => console.log(err));
  };

  const restorePlanning = (id) => {
    setId(id);
    apiPlannings
      .updatePlanning({ in_trashcan: false }, id)
      .then(() => {
        getPlannings();
      })
      .catch((err) => console.log(err));
  };

  const restorePlanningValidation = (id) => {
    if (account?.activePlanning >= 120) {
      setShowModal(true);
    } else {
      restorePlanning(id);
    }
  };

  const filteredData = listPlanning.filter((item) => {
    const matchesTitle = item.title
      .toLowerCase()
      .includes(search.toLowerCase());

    const itemDate = new Date(item.createdAt);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    const matchesDate =
      (!start || itemDate >= start) && (!end || itemDate <= end);
    return matchesTitle && matchesDate;
  });

  const newPlan = () => {
    if (account?.activePlanning >= 120) {
      setShowModal(true);
    } else {
      navigate(`/planejamento`);
    }
  };

  const updateCompanyName = () => {
    if (companies.length) {
      companies.forEach((el) => {
        if (el.isActive && el.cnpj) {
          setCompanyName(el.name);
          setCompanyCnpj(el.cnpj);
        }
      });
    } else {
      setCompanyName("Pessoa Física");
      setCompanyCnpj("");
    }
  };

  useEffect(() => {
    setListPlanning(list);
    setFilterActive(0);
    setLoading(true);
    getPlannings();
  }, []);

  useEffect(() => {
    setListPlanning(list);
    setFilterActive(0);
    setLoading(true);
    getPlannings();
    updateCompanyName();
  }, [principal(companies)]);

  useEffect(() => {
    updateCompanyName();
  }, []);

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearch("");
  };

  return (
    <div className="section-planning">
      <ModalDefault
        show={showModal}
        callBackClose={() => setShowModal(false)}
        square
        title="Limite Máximo de Planejamentos"
      >
        <div className="notes-modal">
          <BaSeHeading6>
            Você atingiu o limite máximo de <strong>120 planejamentos</strong>{" "}
            para o ano de {currentYear}.
          </BaSeHeading6>
          <br />
          <BaSeHeading6>
            Para adicionar novos planejamentos, por favor, exclua um
            planejamento já existente.
          </BaSeHeading6>
          <br />
          <BaSeButton value="Ok" onClick={() => setShowModal(false)} />
        </div>
      </ModalDefault>
      <BoxPillPlanning newPlan={newPlan} loadingButton={loading}>
        <div className="box-title">
          <BaSeHeading3 color="#005eb8" isBold>
            {companyName}
          </BaSeHeading3>
          <BaSeParagraph color="#8B99A7" fontFamily="Alegreya Sans">
            CNPJ: {companyCnpj}
          </BaSeParagraph>
          <BaSeHeading5 isBold>
            Meus Planejamentos ({listPlanning.length})
          </BaSeHeading5>
        </div>
        <div className="wrapper-search">
          <StepComponent filterActive={filterActive} filterType={filterType} />
          <div className="search">
            <BaSeInput
              align="start"
              color="default"
              inputMode="text"
              onChange={(e) => setSearch(e)}
              placeholder="Pesquise por nome do planejamento"
              typeInput="text"
              value={search}
            />
            <button type="button" className="icon">
              <BaSeIcon
                color="#005EB8"
                description="Menu"
                name="search"
                size={24}
              />
            </button>
          </div>

          <div className="search-date">
            <p>Selecione um período de data de criação</p>
            <div className="date">
              <BaSeDatePicker
                startDate={startDate}
                onStartDateChange={setStartDate}
                placeholder="Data de início"
                label="De"
              />
              <BaSeDatePicker
                startDate={endDate}
                onStartDateChange={setEndDate}
                placeholder="Data de término"
                minDate={startDate}
                label="Até"
                isDisabled={!startDate}
              />
            </div>
            {search || startDate ? (
              <div className="clear">
                <button type="button" onClick={() => clearFilter()}>
                  Limpar filtro
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="list-planing">
          {loading ? (
            <Loading />
          ) : (
            <>
              {listPlanning.length ? (
                filteredData.length ? (
                  filteredData.slice(0, planningToShow).map((review, index) => (
                    <CardStatus
                      key={index}
                      data={review}
                      idItem={id}
                      isFinished={review.isFinished}
                      showDuplicate={account?.activePlanning < 120}
                      callback={(e) => {
                        if (e.type === "delete") deletePlanning(e.id);
                        if (e.type === "duplicate") duplicatePlannings(e.id);
                      }}
                    ></CardStatus>
                  ))
                ) : (
                  <div className="empty">
                    <BaSeIcon
                      color="#005EB8"
                      description="Menu"
                      name="business/archive-fill"
                      size={24}
                    />
                    <BaSeHeading5 color="#005eb8" isBold>
                      Nenhum resultado encontrado.
                    </BaSeHeading5>
                  </div>
                )
              ) : (
                <BaSeHeading5 color="#005eb8" isBold>
                  Lista vazia
                </BaSeHeading5>
              )}

              <div className="more-planning">
                {planningToShow < listPlanning.length && filteredData.length ? (
                  <button
                    type="button"
                    onClick={() => setPlanningToShow(planningToShow + count)}
                  >
                    Carregar mais planejamentos
                  </button>
                ) : null}
              </div>

              <div className="trash-planning">
                <Accordion
                  title="Planejamentos excluídos"
                  titleLength={listTrash.length}
                  forceHeightUpdate={updateTrash}
                >
                  {listTrash.length ? (
                    listTrash.map((item, k) => (
                      <CardTrash
                        key={k}
                        idItem={id}
                        data={item}
                        callback={(id) => {
                          restorePlanningValidation(id);
                        }}
                      />
                    ))
                  ) : (
                    <div className="list-trash" style={{ minHeight: "auto" }}>
                      <div className="title">
                        <BaSeHeading5 color="#005eb8">Lista vazia</BaSeHeading5>
                      </div>
                    </div>
                  )}
                </Accordion>
              </div>
            </>
          )}
        </div>
      </BoxPillPlanning>
    </div>
  );
};

export default MyPlanning;
