import Requests from "./requests";

const ApiOptIn = () => {
  const instance = Requests();

  const getOptIn = () => {
    return instance.get("/get-opt-in/");
  };

  const postOptIn = () => {
    return instance.post("/get-opt-in/", {});
  };

  return { getOptIn, postOptIn };
};

export default ApiOptIn;
