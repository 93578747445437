import Requests from "./requests";

const ApiCMS = () => {
  const instance = Requests();

  const getAllPills = (page, page_size) => {
    return instance.get("/api/cms/", {
      params: {
        page: page,
        page_size: page_size,
      },
    });
  };

  const getPillById = (id, cnpj) => {
    return instance.get(`/api/cms/${id}`, {
      params: {
        cnpj,
      },
    });
  };

  return { getAllPills, getPillById };
};

export default ApiCMS;
