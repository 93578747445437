export function getToken() {
  return localStorage.getItem("token") || false;
}

export function decodeJWT(token) {
  if (token !== undefined) {
    const base64Url = token.split(".")[1] || null;
    if (base64Url) {
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
  }
}

export function isEqual(obj1, obj2) {
  if (obj1?.__proto__ !== obj2?.__proto__) {
    return false;
  }
  if (Array.isArray(obj1)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i in obj1) {
      if (!isEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }
  if (obj1 instanceof Object && typeof obj1 !== "function") {
    if (
      Object.keys(obj1)
        .filter((k) => obj1[k] !== undefined)
        .sort()
        .toString() !==
      Object.keys(obj2)
        .filter((k) => obj2[k] !== undefined)
        .sort()
        .toString()
    ) {
      return false;
    }
    for (let i in obj1) {
      if (!isEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }
  return obj1 === obj2;
}

export function isNotEqual(obj1, obj2) {
  return !isEqual(obj1, obj2);
}

export const converterSnakeToCamelCase = (obj) => {
  let newObj = {};

  for (let d in obj) {
    if (obj.hasOwnProperty(d)) {
      newObj[
        // eslint-disable-next-line
        d.replace(/(\_\w)/g, (k) => {
          return k[1].toUpperCase();
        })
      ] = obj[d];
    }
  }
  return newObj;
};

const camelToUnderscore = (key) => {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

export const converterCamelCaseToSnake = (obj) => {
  let newObj = {};

  for (let d in obj) {
    if (obj.hasOwnProperty(d)) {
      newObj[camelToUnderscore(d)] = obj[d];
    }
  }
  return newObj;
};

export function convertToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to = "aaaaaeeeeeiiiiooooouuuunc------";
  let i = 0,
    l = from.length;
  for (; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export function sumValuesPercent(arr, property = "value") {
  return arr.reduce((sum, item) => sum + parseFloat(item[property] || 0), 0);
}

export const principal = (data) => {
  if (data && data.length) {
    const principal = data.filter((company) => {
      return company.isActive;
    });

    return principal[0]?.cnpj;
  }
  return "";
};

export function formatCurrency(
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function formatFloat(value) {
  if (value === null || value === undefined) return 0

  value = value.toString()
  const parsed = Number(parseFloat(value.replace(/\./g, "").replace(",", ".")));
  return isNaN(parsed) ? 0 : parsed;
}

export function convertObjectValuesToFloat(data) {
  const recursiveConvert = (obj) => {
    if (typeof obj === "string") {
      return formatFloat(obj);
    }

    if (typeof obj === "object" && obj !== null) {
      return Array.isArray(obj)
        ? obj.map(recursiveConvert)
        : Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
              key,
              recursiveConvert(value),
            ])
          );
    }
    return obj;
  };

  return recursiveConvert(data);
}

export function sortedArrayByObjectKeys(array) {
  return array.sort((a, b) => a.order - b.order);
}

export const formatMonth = (data) => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const [ano, mes] = data.split("-");
  const mesNome = months[parseInt(mes, 10) - 1];
  return `${mesNome} ${ano}`;
};

export const dataMonth = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate > endDate) {
    throw new Error("A data de início deve ser menor ou igual à data de fim.");
  }

  const result = [];
  let count = 1;
  let dateNow = new Date(startDate);

  while (dateNow <= endDate) {
    const mes = dateNow.getMonth() + 1;
    const ano = dateNow.getFullYear();

    result.push({
      id: count,
      label: `${count} mês${count > 1 ? "es" : ""}`,
      value: "",
      date: `${String(mes).padStart(2, "0")}/${ano}`,
    });

    dateNow = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1);
    count++;
  }

  return result;
};

export const validateLinkVideo = (link) => {
  const baseEmbedUrl = "https://www.youtube.com/embed/";

  if (/^[a-zA-Z0-9_-]{11}$/.test(link)) {
    return `${baseEmbedUrl}${link}`;
  }

  if (!link.startsWith("http")) {
    link = "https://" + link;
  }

  if (link.startsWith(baseEmbedUrl)) {
    return link;
  }

  if (link.includes("youtube.com/watch?v=")) {
    const videoId = link.split("v=")[1]?.split("&")[0];
    if (videoId) {
      return `${baseEmbedUrl}${videoId}`;
    }
    return null;
  }

  return null;
};

export function getDateTomorrow() {
  let tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);

  return tomorrow;
}

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (char) {
      const random = (Math.random() * 16) | 0;
      // eslint-disable-next-line
      const value = char === "x" ? random : (random & 0x3) | 0x8;
      return value.toString(16);
    }
  );
}

export function generateDateRange(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const dateRange = [];

  let currentMonth = startDate.getMonth();
  let currentYear = startDate.getFullYear();

  const endMonth = endDate.getMonth();
  const endYear = endDate.getFullYear();

  while (
    currentYear < endYear ||
    (currentYear === endYear && currentMonth <= endMonth)
  ) {
    const month = (currentMonth + 1).toString().padStart(2, "0");
    dateRange.push(`${month}-${currentYear}`);
    currentMonth += 1;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear += 1;
    }
  }
  return dateRange;
}

export function addMonths(date, numberOfMonths) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + numberOfMonths);

  return newDate;
}

export function firstDayOfMonth(date) {
  const firstDay = new Date(date);
  firstDay.setDate(1);
  return firstDay;
}

export function areDatesEqualIgnoringTime(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function formatMonthAndYearName(dateName) {
  const [year, month] = dateName.split('-')
  return `${month}-${year}`
}

export function formatMonthAndYearNameToDate(monthYear) {
  const [month, year] = monthYear.split('-').map(Number);
  return new Date(year, month - 1, 1);
}
export function formatYearAndMonthNameToDate(monthYear) {
  const [year, month] = monthYear.split('-').map(Number)
  return new Date(year, month - 1, 1)
}

export function formatYearMonthToFullDate(yearMonth) {
  const [year, month] = yearMonth.split('-').map(Number)
  const date = new Date(year, month - 1)
  const monthName = date.toLocaleString('pt-BR', { month: 'long' })

  return `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${year}`
}

export function formatDateToYearMonthString(date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  return `${year}-${month}`
}

export function getPeriodString(data) {
  if (!data.length) return "Nenhum período disponível"
  const firstMonth = data[0]?.mes
  const lastMonth = data[data.length - 1]?.mes
  const formatMonthYear = (monthYear) => {
    const [year, month] = monthYear.split("-")
    return `${month}/${year.slice(2)}`
  }

  return `De ${formatMonthYear(firstMonth)} a ${formatMonthYear(lastMonth)}`;
}

export function sortByMonthAndIgnoreEmpty(array) {
  return array
    .filter((item) => !item.isEmpty)
    .sort((a, b) => {
    const dateA = formatYearAndMonthNameToDate(a.mes)
    const dateB = formatYearAndMonthNameToDate(b.mes)
    return dateA - dateB
  })
}

export function reorderEntriesBySections(sections, saidas) {
  const sectionOrder = sections.reduce((acc, section, index) => {
    acc[section.sectionId] = index
    return acc
  }, {})

  saidas.forEach((saida) => {
    if (saida.entries && Array.isArray(saida.entries)) {
      sections.forEach((section) => {
        const exists = saida.entries.some((entry) => entry.uuid === section.sectionId)
        if (!exists) {
          saida.entries.push({
            uuid: section.sectionId,
            title: section.title,
            val: 0,
            isEmpty: true,
          })
        }
      })
      saida.entries.sort((a, b) => {
        const orderA = sectionOrder[a.uuid] ?? Infinity
        const orderB = sectionOrder[b.uuid] ?? Infinity
        return orderA - orderB
      })
    }
  })

  return saidas
}