import {useEffect, useRef, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {decodeJWT, getToken} from "./services/utils";
import Layout from "./components/layout";
import {BaSeNotFoundPage} from "@base/react";
import Home from "./pages/home";
import MyPlanning from "./pages/my-planning";
import Auth from "./pages/auth";
import Planning from "./pages/planning";
import AllPills from "./pages/all-pills";
import PillContent from "./pages/pill-content";
import PillPlanning from "./pages/pill-planning";
import {CompaniesProvider} from "./context/companies";
import Instructions from "./pages/instructions";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Unauthorized from "./pages/unauthorized";
import ApiAuth from "./services/auth";
import Loading from "./components/loading";

moment.locale("pt-br", {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
});

function App() {
  const location = useLocation();
  const previousLocation = useRef(null);
  const [loading, setLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [account, setAccount] = useState(null);
  const companies = JSON.parse(localStorage.getItem("companies")) || "";

  const propsDefault = {
    account,
    setAccount,
    companies,
  };

  const getUserProfile = (token) => {
    const userData = decodeJWT(token);
    setAccount(userData);
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      getUserProfile(token);
    }
    setLoading(false);
    if (localStorage.getItem("unauthorized")) {
      ApiAuth().logout()
    }

    setForceRefresh(location.pathname === "/planejamento")
    setTimeout(() => {
      setForceRefresh(false)
    }, 300)
    previousLocation.current = location.pathname;
  }, [location]);

  return (
    <CompaniesProvider>
      <Layout {...propsDefault}>
        {loading || forceRefresh ? (
          <Loading fullPage />
        ) : (
          <Routes>
            {!account ? (
              <Route path="/" element={<Auth {...propsDefault} />} />
            ) : account?.unauthorized ? (
              <Route path="/" element={<Unauthorized {...propsDefault} />} />
            ) : (
              <>
                <Route path="/" element={<Home {...propsDefault} />} />
                <Route
                  path="/planejamento/:idEdit?/:step?"
                  element={<Planning {...propsDefault} />}
                />
                <Route
                  path="/meus-planejamentos"
                  element={<MyPlanning {...propsDefault} />}
                />
                <Route
                  path="/planejadora-sebrae"
                  element={<PillPlanning {...propsDefault} />}
                />
                <Route
                  path="/conteudo"
                  element={<AllPills {...propsDefault} />}
                />
                <Route
                  path="/pilula/:id"
                  element={<PillContent {...propsDefault} />}
                />
                <Route
                  path="/como-utilizar"
                  element={<Instructions {...propsDefault} />}
                />
              </>
            )}
            <Route path="*" element={<BaSeNotFoundPage />} />
          </Routes>
        )}

        <ToastContainer role="alert" hideProgressBar={true} autoClose={5000} />
      </Layout>
    </CompaniesProvider>
  );
}

export default App;
