import "./index.scss";
import { Link } from "react-router-dom";

const Breadcrump = ({ items, color, separator = ">" }) => {
  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className="item" style={{ color: color }}>
            {item.href ? (
              <Link
                to={item.href}
                style={{ textDecoration: "none", color: color }}
              >
                {item.label}
              </Link>
            ) : (
              <span>{item.label}</span>
            )}
            {index < items.length - 1 && (
              <span className="separator" style={{ color: color }}>
                {separator}
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrump;
