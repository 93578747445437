import {validateCurrency} from "../../../services/validate";
import {
  BaSeButton,
  BaSeCheckbox,
  BaSeDatePicker,
  BaSeHeading5,
  BaSeIcon,
  BaSeInput,
  BaSeOptionPicker,
} from "@base/react";
import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import {
  addMonths,
  areDatesEqualIgnoringTime,
  firstDayOfMonth,
  formatCurrency,
  formatDateToYearMonthString,
  formatFloat,
  formatMonthAndYearName,
  formatMonthAndYearNameToDate,
  formatYearAndMonthNameToDate,
  formatYearMonthToFullDate,
  generateDateRange,
  getPeriodString,
  reorderEntriesBySections,
  sortByMonthAndIgnoreEmpty,
  uuid,
} from "../../../services/utils";
import Slider from "react-slick";
import ModalDefault from "../../../components/modals/modal-default";
import StepComponent from "../../../components/step-component";
import {StepsModal} from "../../../mocks/default";
import moment from "moment/moment";
import MenuDropdown from "../../../components/menu-dropdown";
import Dialog from "../../../components/dialog";

const periods = () => {
  const periods = [];
  const maxPeriods = 120;

  for (let p = 1; p <= maxPeriods; p++) {
    periods.push({
      id: p,
      label: `${p} ${p > 1 ? "meses" : "mês"}`,
    });
  }

  return periods;
};

const ProjectedCashFlow = ({
                             dataPlanning,
                             sendForm,
                             backStep,
                             goNextStep,
                           }) => {
  const [calendarType, setCalendarType] = useState("days");
  const [stepActive, setStepActive] = useState(0);
  const [showModal, setShowModal] = useState({
    status: false,
    type: "create",
  });
  const [initialBalance, setInitialBalance] = useState(false);
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const dataContent = dataPlanning?.data?.fluxo || {
    mesBase: firstDayOfMonth(new Date()),
    saldoInicial: 0,
    saidas: [],
  };
  const [form, setForm] = useState({
    mesBase: dataContent.mesBase.length
      ? formatYearAndMonthNameToDate(dataContent.mesBase)
      : firstDayOfMonth(new Date()),
    saldoInicial: formatCurrency(dataContent.saldoInicial) || 0,
  });
  const formAddExitDefault = {
    uuid: uuid(),
    title: "",
    different: false,
    startMonth: dataContent.mesBase.length
      ? formatYearAndMonthNameToDate(dataContent.mesBase)
      : firstDayOfMonth(new Date()),
    endMonth: null,
    numberOfMonths: 1,
    fields: [
      {
        label: "Valor das parcelas",
        val: 0,
      },
    ],
  };
  const [formAddExit, setFormAddExit] = useState(formAddExitDefault);
  const [menuOptions, setMenuOptions] = useState(null);
  const SettingsSliderCashFlow = {
    dots: dataContent?.saidas.length >= 1,
    infinite: false,
    speed: 500,
    slidesToShow: dataContent?.saidas.length
      ? dataContent?.saidas.length >= 2
        ? 3
        : dataContent?.saidas.length
      : 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    fade: false,
    centerMode: false,
    adaptiveHeight: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20",
          variableWidth: false,
        },
      },
    ],
  };

  const changeTabs = (type, active) => {
    setStepActive(active);
    setCalendarType(type);
  };
  const updateField = (index, newValue) => {
    setFormAddExit((prevState) => {
      const updatedFields = [...prevState.fields];
      updatedFields[index] = { ...updatedFields[index], val: newValue };
      return {
        ...prevState,
        fields: updatedFields,
      };
    });
  };
  const formatData = (input) => {
    const { uuid, title, different, startMonth, endMonth, fields } = input;
    const dateRange = generateDateRange(startMonth, endMonth);

    const formattedFields = dateRange.map((date, index) => {
      return {
        [date]: different ? fields[index]?.val : fields[0]?.val,
      };
    });
    return {
      [uuid]: {
        title,
        different,
        fields: formattedFields,
      },
    };
  };
  const formatReverseData = (input) => {
    const [uuid, data] = Object.entries(input)[0]
    const { title, different, fields } = data

    const parsedFields = fields.map((field) => {
      // eslint-disable-next-line
      const [key, val] = Object.entries(field)[0]
      return { label: "Valor das parcelas", val }
    })

    const dateKeys = fields.map((field) => Object.keys(field)[0])
    const startMonth = formatMonthAndYearNameToDate(dateKeys[0])
    const endMonth = formatMonthAndYearNameToDate(dateKeys.at(-1))
    const numberOfMonths = dateKeys.length

    return {
      uuid,
      title,
      different,
      startMonth: startMonth,
      endMonth: endMonth,
      numberOfMonths,
      fields: parsedFields,
    }
  }
  const formatFieldsCheckDifferentValues = () => {
    const { different, startMonth, endMonth, fields } = formAddExit;
    const dateRange = generateDateRange(startMonth, endMonth);
    let modifiedFields = [
      {
        label: "Valor das parcelas",
        val: fields[0]?.val || 0,
      },
    ];
    if (different) {
      modifiedFields = dateRange.map((date, index) => ({
        label: `Valor ${date}`,
        val: fields[index]?.val || 0,
      }));
    }

    return {
      ...formAddExit,
      fields: modifiedFields,
    };
  };
  const updateStartEndDate = (type, value) => {
    setFormAddExit((prevForm) => {
      const { startMonth, endMonth } = prevForm;

      if (type === "select") {
        return {
          ...prevForm,
          numberOfMonths: value,
          endMonth: addMonths(startMonth, value - 1),
        };
      } else if (type === "start") {
        return {
          ...prevForm,
          numberOfMonths: generateDateRange(value, endMonth).length,
          startMonth: value,
        };
      } else {
        return {
          ...prevForm,
          endMonth: value,
          numberOfMonths: generateDateRange(startMonth, value).length,
        };
      }
    });
  };
  const mergeDataModalIntoSaidas = (dataModal) => {
    const result = { ...dataContent };
    const uuid = Object.keys(dataModal)[0];
    const modalEntry = dataModal[uuid];

    for (const saida of result.saidas) {
      saida.entries = saida.entries.filter((entry) => entry.uuid !== uuid);
    }

    result.saidas = result.saidas.filter((saida) => saida.entries.length > 0);

    for (const field of modalEntry.fields) {
      const [mes, ano] = Object.keys(field)[0].split("-");
      const fullDate = `${ano}-${mes}`;
      const val = field[`${mes}-${ano}`];
      let saida = result.saidas.find((saida) => saida.mes === fullDate);

      if (!saida) {
        saida = { mes: fullDate, entries: [] };
        result.saidas.push(saida);
      }

      saida.entries.push({
        uuid: uuid,
        title: modalEntry.title,
        val: formatFloat(val),
        different: modalEntry.different,
      });
    }

    return result.saidas;
  }
  const saveDialogAddExit = (exits = null) => {
    const dataModalAddExit = formatData(formAddExit)
    const dataModalInitialBalance = form
    const mergeDataIntoSaidas = mergeDataModalIntoSaidas(dataModalAddExit)
    if (!dataPlanning?.is_finished) {
      sendForm(
        {
          ...dataPlanning,
          data: {
            ...dataPlanning?.data,
            fluxo: {
              mesBase: formatDateToYearMonthString(
                dataModalInitialBalance.mesBase
              ),
              saldoInicial: formatFloat(dataModalInitialBalance.saldoInicial),
              saidas: sortByMonthAndIgnoreEmpty(exits || mergeDataIntoSaidas),
            },
          },
        },
        true
      )
      setShowModal({ status: false, type: "create" })
    }
  }
  const extractSections = (data) => {
    const sections = [];

    for (const saida of data.saidas) {
      const { entries } = saida;

      for (const entry of entries) {
        const { uuid, title } = entry;
        if (!sections.some((section) => section.sectionId === uuid)) {
          sections.push({ title, sectionId: uuid });
        }
      }
    }

    return sections;
  };
  const generateDataModalForEdit = (targetUuid) => {
    const dataModal = {}

    for (const saida of dataContent.saidas) {
      const { mes, entries } = saida

      for (const entry of entries.filter((item) => !item.isEmpty)) {
        const { uuid, title, val, different } = entry

        if (uuid === targetUuid) {
          if (!dataModal[uuid]) {
            dataModal[uuid] = {
              title: title,
              different: different,
              fields: [],
            }
          }

          dataModal[uuid].fields.push({
            [formatMonthAndYearName(mes)]: formatCurrency(val),
          })
        }
      }
    }

    return dataModal
  }
  const openDialogForEdit = (id) => {
    const dataEdit = generateDataModalForEdit(id);
    setFormAddExit({ ...formatReverseData(dataEdit) });
    setShowModal({ status: true, type: "edit" });
    setMenuOptions(null)
  }
  const removeExitsById = (id) => {
    const newExists = dataContent?.saidas
      .map((item) => ({
        ...item,
        entries: item.entries.filter((entry) => entry.uuid !== id),
      }))
      .filter((item) => item.entries.length > 0)

    setMenuOptions(null)
    saveDialogAddExit(newExists)
  }
  const filterArrayByDateRange = (array, mesBase, mesFinal) => {
    const filteredArray = array.filter((item) => {
      const itemDate = formatYearAndMonthNameToDate(item.mes);
      return itemDate >= mesBase && itemDate <= mesFinal;
    })
    return {
      isOutOfRange: filteredArray.length !== array.length,
      data: filteredArray,
    }
  }
  const getMonthBaseAndMore120Months = () => {
    const monthBase = form?.mesBase
    const monthFinal = addMonths(form?.mesBase, 120)

    return {
      monthBase: monthBase,
      monthFinal: monthFinal,
    }
  }
  const addBalanceInitial = () => {
    const dataModalAddExit = formatData(formAddExit)
    const mergeDataIntoSaidas = mergeDataModalIntoSaidas(dataModalAddExit)
    const arrayExits = sortByMonthAndIgnoreEmpty(mergeDataIntoSaidas)
    const byDataRange = filterArrayByDateRange(arrayExits, getMonthBaseAndMore120Months().monthBase, getMonthBaseAndMore120Months().monthFinal)
    if (byDataRange.isOutOfRange) {
      setShowDialogConfirm(true)
    } else {
      saveDialogAddExit()
    }
  }
  const confirmChangeBalanceInitial = () => {
    const dataModalAddExit = formatData(formAddExit)
    const mergeDataIntoSaidas = mergeDataModalIntoSaidas(dataModalAddExit)
    const arrayExits = sortByMonthAndIgnoreEmpty(mergeDataIntoSaidas)
    const byDataRange = filterArrayByDateRange(arrayExits, getMonthBaseAndMore120Months().monthBase, getMonthBaseAndMore120Months().monthFinal)
    saveDialogAddExit(byDataRange.data)
  }

  useEffect(() => {
    if (!showModal.status) {
      setFormAddExit(formAddExitDefault)
    }
    // eslint-disable-next-line
  }, [showModal]);
  useEffect(() => {
    setFormAddExit({ ...formAddExit, ...formatFieldsCheckDifferentValues() });
    // eslint-disable-next-line
  }, [formAddExit?.different, formAddExit?.startMonth, formAddExit?.endMonth]);

  return (
    <div className="step cash-flow">
      <BaSeHeading5
        color="#005EB8"
        fontFamily="Alegreya Sans"
        isBold
        className="mt-0"
      >
        Mês atual:
      </BaSeHeading5>
      <div className="wrapper-flux title">
        <p className="date">{moment().format("MMMM [de] YYYY")}</p>
      </div>

      <ModalDefault
        show={showModal.status}
        title={showModal.type === "create" ? "Adicionar saída" : "Editar saída"}
        callBackClose={() => setShowModal({ ...showModal, status: false })}
      >
        <Formik initialValues={form} onSubmit={() => saveDialogAddExit()}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="wrapper-search">
                <div className="search">
                  <BaSeInput
                    color="default"
                    inputMode="text"
                    typeInput="text"
                    inputStatus={formAddExit.title ? "valid" : "invalid"}
                    value={formAddExit.title}
                    onChange={(e) => {
                      setFormAddExit({ ...formAddExit, title: e });
                    }}
                    isDisabled={dataPlanning?.is_finished}
                    placeholder="Escolha um nome para a saída"
                    size="medium"
                    label="Nome da saída"
                    width="100%"
                  />
                </div>
                <div className="form-date">
                  <p className="description">
                    Período da saída
                    <span>
                      Selecione um mês inicial e um mês final ou a quantidade de
                      meses
                    </span>
                  </p>

                  <StepComponent
                    stepActive={stepActive}
                    filterType={changeTabs}
                    listSteps={StepsModal}
                  />

                  <div className="date">
                    {calendarType === "months" && (
                      <div className="wrapper-select">
                        <BaSeOptionPicker
                          label="Quantidade de meses"
                          placeholder="teste"
                          searchPlaceholder="Busca pelo nome"
                          values={periods()}
                          maxOptionsSelected={1}
                          initialSelectedValuesId={[
                            formAddExit?.numberOfMonths,
                          ]}
                          emptyValueLabel="Selecione uma opção"
                          onChange={(values) => {
                            updateStartEndDate(
                              "select",
                              values.map((val) => val.id)[0]
                            );
                          }}
                          isDisabled={dataPlanning?.is_finished}
                        />
                      </div>
                    )}
                    <div className="wrapper-date">
                      <BaSeDatePicker
                        calendarType="months"
                        startDate={formAddExit.startMonth}
                        maxDate={formAddExit.endMonth}
                        onStartDateChange={(e) =>
                          updateStartEndDate("start", e)
                        }
                        placeholder="Data de início"
                        label="Data inicial"
                        isDisabled={dataPlanning?.is_finished}
                        minDate={formAddExitDefault.startMonth}
                      />
                      {calendarType === "days" && (
                        <BaSeDatePicker
                          calendarType="months"
                          startDate={formAddExit.endMonth}
                          onStartDateChange={(e) =>
                            updateStartEndDate("end", e)
                          }
                          placeholder="Data de término"
                          minDate={formAddExit.startMonth}
                          maxDate={
                            formAddExit.startMonth
                              ? addMonths(new Date(formAddExit.startMonth), 120)
                              : undefined
                          }
                          label="Data final"
                          isDisabled={
                            dataPlanning?.is_finished || !formAddExit.startMonth
                          }
                        />
                      )}
                    </div>
                    <div className="additional-month">
                      {formAddExit.fields.map((field, index) => {
                        return (
                          <div
                            key={`${index}-${field.label}`}
                            className="currency"
                          >
                            <BaSeInput
                              color="default"
                              inputMode="tel"
                              typeInput="tel"
                              inputStatus={
                                validateCurrency(field.val)
                                  ? "invalid"
                                  : "valid"
                              }
                              value={field.val}
                              onChange={(e) => updateField(index, e)}
                              isDisabled={dataPlanning?.is_finished}
                              placeholder="R$"
                              maxLength={22}
                              prefix="R$ "
                              mask="decimal"
                              size="medium"
                              label={field.label.replace("-", "/")}
                              width="100%"
                            />
                          </div>
                        );
                      })}
                    </div>
                    <BaSeCheckbox
                      key={formAddExit.different}
                      disabled={
                        dataPlanning?.is_finished ||
                        (calendarType === "months"
                          ? !formAddExit.startMonth
                          : !formAddExit.startMonth ||
                          !formAddExit.endMonth ||
                          areDatesEqualIgnoringTime(
                            formAddExit?.startMonth,
                            formAddExit?.endMonth
                          ))
                      }
                      label="Valores diferentes"
                      shape="square"
                      size="small"
                      checked={formAddExit.different}
                      onChange={(e) =>
                        setFormAddExit({ ...formAddExit, different: e })
                      }
                    />
                    <div className="wrapper-button">
                      <button
                        type="submit"
                        onClick={() => handleSubmit}
                        disabled={dataPlanning?.is_finished}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalDefault>

      <ModalDefault
        show={initialBalance}
        title="Adicionar saldo inicial"
        callBackClose={() => setInitialBalance(false)}
      >
        <Formik initialValues={form} onSubmit={() => addBalanceInitial()}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-initial-values">
                <p className="description">Período do saldo</p>

                <div className="wrapper-date">
                  <BaSeDatePicker
                    calendarType="months"
                    startDate={form.mesBase}
                    onStartDateChange={(e) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        mesBase: e,
                      }))
                    }
                    placeholder="Data de início"
                    label="Data inicial"
                    isDisabled={dataPlanning?.is_finished}
                    minDate={firstDayOfMonth(new Date())}
                  />
                </div>
                <div className="currency">
                  <BaSeInput
                    color="default"
                    inputMode="tel"
                    typeInput="tel"
                    defaultValue={0}
                    inputStatus={
                      validateCurrency(form.saldoInicial) ? "invalid" : "valid"
                    }
                    value={form.saldoInicial}
                    onChange={(e) => {
                      if (e.replace(".", "") === "000") {
                        setForm({
                          ...form,
                          saldoInicial: 0,
                        });
                      } else {
                        setForm({
                          ...form,
                          saldoInicial: e,
                        });
                      }
                    }}
                    isDisabled={dataPlanning?.is_finished}
                    placeholder="R$"
                    maxLength={22}
                    prefix="R$ "
                    mask="decimal"
                    size="medium"
                    label="Valor do saldo inicial"
                    width="100%"
                  />
                </div>
                <div className="wrapper-button">
                  <button
                    disabled={
                      dataPlanning?.is_finished ||
                      !form.saldoInicial ||
                      !form.mesBase
                    }
                    type="submit"
                    onClick={() => handleSubmit}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalDefault>

      <Dialog
        show={showDialogConfirm}
        title="Atenção!"
        description={`
                Você está prestes a alterar o mês inicial do seu fluxo projetado. Todos os registros de saída que não estiverem dentro de 120 meses a partir do mês inicial serão excluídos. <br /><br />
                Deseja continuar?
              `}
        btnCancel="Cancelar"
        btnConfirm="Confirmar"
        onHide={() => setShowDialogConfirm(false)}
        callbackCancel={() => setShowDialogConfirm(false)}
        callbackConfirm={() => confirmChangeBalanceInitial()}
      />

      <Slider
        className={
          "SettingsSliderCashFlow" +
          (!dataContent?.saidas.length ? " empty" : "")
        }
        {...SettingsSliderCashFlow}
      >
        <div className="wrapper-flux">
          <h3>Fluxo de caixa projetado</h3>
          <span
            className={
              "card-flux balance" +
              (dataContent.saldoInicial > 0 ? " has-balance" : "")
            }
          >
            <div className="header">
              Saldo inicial em caixa
              <BaSeIcon
                onClick={() => setInitialBalance(true)}
                color={dataContent.saldoInicial > 0 ? "#617385" : "#005EB8"}
                description="Menu"
                name={
                  dataContent.saldoInicial > 0
                    ? "system/more-2-fill"
                    : "plus-square"
                }
                size={dataContent.saldoInicial > 0 ? 18 : 24}
              />
            </div>
            {dataContent.saldoInicial > 0 ? (
              <div className="has-balance">
                <p>Período: {getPeriodString(dataContent.saidas)}</p>
                <p>Valor: R$ {formatCurrency(dataContent.saldoInicial)}</p>
              </div>
            ) : (
              ""
            )}
          </span>
          <span className="card-flux">Entradas</span>
          <div className="exits">
            <div className="card-flux">
              <div className="content">
                <div className="title">
                  Saídas
                  <BaSeIcon
                    onClick={() =>
                      setShowModal({ status: true, type: "create" })
                    }
                    color="#005EB8"
                    description="Menu"
                    name="plus-square"
                    size={24}
                  />
                </div>
                <div className="title-values">
                  {dataContent?.saidas.length
                    ? extractSections(dataContent).map((item) => (
                      <div className="title-exits" key={item.sectionId}>
                        <p>{item.title || 'Saída'}</p>
                        <MenuDropdown data={[
                          {
                            name: "Editar",
                            icon: <BaSeIcon
                              color="#617385"
                              description="Edit"
                              name="design/pencil-line"
                              size={24}
                            />,
                            callback: () => {
                              openDialogForEdit(item.sectionId)
                            }
                          },
                          {
                            name: "Excluir",
                            icon: <BaSeIcon
                              color="#617385"
                              description="Trash"
                              name="trash-alt"
                              size={24}
                            />,
                            callback: () => {
                              removeExitsById(item.sectionId)
                            }
                          }
                        ]}
                                      isOpen={menuOptions === item.sectionId}
                                      click={() => setMenuOptions(menuOptions !== item.sectionId ? item.sectionId : null)} />
                      </div>
                    ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <span className="card-flux capacity card-flux-gray active">
            Capacidade mensal de pagamento
          </span>
        </div>
        {dataContent?.saidas.length
          ? reorderEntriesBySections(extractSections(dataContent), dataContent?.saidas).map((item, index) => {
            const sumTotalSaidas = item.entries.reduce(
              (acc, exits) => acc + exits.val,
              0
            );
            const getFluxgraph = Array.isArray(
              dataPlanning?.results?.fluxgraph
            )
              ? dataPlanning.results?.fluxgraph.find(
                (graph) => graph.mes === item.mes
              )
              : null;
            return (
              <div key={index} className="wrapper-flux dynamic">
                <h3 className="gradient">
                  {index + 1} - {formatYearMonthToFullDate(item.mes)}
                </h3>
                <span
                  className={
                    "card-flux balance small" +
                    (dataContent.saldoInicial > 0 ? " has-balance" : "")
                  }
                >
                    R$ {formatCurrency(getFluxgraph?.saldo)}
                  </span>
                <span className="card-flux">
                    R$ {formatCurrency(dataPlanning?.results?.l)}
                  </span>
                <div className="exits wrapper-card">
                  <div className="card-flux">
                    <p>R$ {formatCurrency(sumTotalSaidas)}</p>
                  </div>
                  {item.entries.map((val) => (
                    <div key={val.uuid} className={"card-flux small"+(val?.isEmpty ? " empty" : "")}>
                      <p>R$ {formatCurrency(val?.val)}</p>
                    </div>
                  ))}
                </div>
                <span
                  className="card-flux capacity card-flux-gray active"
                  style={{
                    backgroundColor:
                      getFluxgraph?.capacidadeMensal < 0
                        ? "#F28A8E"
                        : "#CfE2FC",
                    color:
                      getFluxgraph?.capacidadeMensal < 0
                        ? "#BF303C"
                        : "#005EB8",
                  }}
                >
                    R$ {formatCurrency(getFluxgraph?.capacidadeMensal)}
                  </span>
              </div>
            );
          })
          : ""}
      </Slider>

      <div className="planning__footer">
        <BaSeButton
          type="secondary"
          buttonType="button"
          onClick={() => backStep()}
          color="#005eb8"
          size="medium"
          value="Anterior"
          width="87px"
        />

        <BaSeButton
          buttonType="submit"
          size="medium"
          value="Próximo"
          width="87px"
          onClick={() => goNextStep()}
        />
      </div>
    </div>
  );
};

export default ProjectedCashFlow;
