import "./index.scss";
import Acronyms from "../../acronyms";
import { BaSeCheckbox, BaSeParagraph, BaSeSmall2 } from "@base/react";

const CardInputCompany = ({ data, callBack }) => {
  return (
    <>
      <input
        type="radio"
        id={`companies-${data.cnpj}`}
        name={`companies-${data.cnpj}`}
        value={data.isActive}
        checked={data.isActive}
        onChange={() => callBack(data.cnpj)}
        style={{ display: "none" }}
      />
      <label htmlFor={`companies-${data.cnpj}`}>
        <div
          className={`company__radio ${data.isActive ? "selected" : ""}`}
          key={data.cnpj}
        >
          <div className="company__radio__data">
            <Acronyms nameUser={data.name} />
            <div className="company__radio__data__text">
              <BaSeParagraph className="company-name" isBold color="#005EB8">
                {data.name}
              </BaSeParagraph>
              <BaSeSmall2 color="#617385">CNPJ: {data.cnpj}</BaSeSmall2>
            </div>
          </div>
          <div className="company__radio__checkbox-comp">
            <BaSeCheckbox
              shape="circle"
              id={data.cnpj}
              name={`companies-${data.cnpj}`}
              value={data.isActive}
              checked={data.isActive}
            />
          </div>
        </div>
      </label>
    </>
  );
};

export default CardInputCompany;
