import { validateCurrency } from "../../../services/validate";
import {
  BaSeButton,
  BaSeGrid,
  BaSeGridItem,
  BaSeHeading5,
  BaSeInput,
  BaSeOptionPicker,
} from "@base/react";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  formatCurrency,
  formatFloat,
  principal,
} from "../../../services/utils";
import logoFampe from "../../../assets/images/fampe.png";
import Loading from "../../../components/loading";
import ApiCompaniesSizes from "../../../services/companiesSizes";
const apiCompaniesSizes = ApiCompaniesSizes();

const companySizes = [
  {
    id: 99,
    label: "Microempreendedor Individual",
    ceiling: 100000,
  },
  {
    id: 2,
    label: "Microempresa",
    ceiling: 400000,
  },
  {
    id: 3,
    label: "Empresa de pequeno porte",
    ceiling: 700000,
  },
];
const Guarantees = ({ backStep, companies }) => {
  const [form, setForm] = useState({
    f: 0,
    geb: 0,
    fampe: "0,00",
    cng: "0,00",
  });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([companySizes[0].id]);
  const getCeiling = useCallback(() => {
    const currentCompanySize = companySizes.find(
      (item) => item.id === selected[0]
    );
    return currentCompanySize.ceiling;
  }, [selected]);

  const calcFampe = () => {
    let result = 0;
    if (form.f) {
      const value = formatFloat(form.f);
      result = (80 / 100) * value;
    }
    return setForm({ ...form, fampe: formatCurrency(result) });
  };
  const calcCNG = () => {
    let result = 0;
    if (form.geb) {
      const geb = formatFloat(form.geb);
      let fampe = formatFloat(form.fampe);
      fampe = fampe > getCeiling() ? getCeiling() : fampe;
      result = geb - fampe;
    }

    return setForm({ ...form, cng: formatCurrency(result) });
  };

  const getCompaniesSizes = () => {
    setLoading(true);
    apiCompaniesSizes
      .getCompaniesSizes(principal(companies))
      .then((response) => {
        const { data } = response;

        const selectedCompanySize = companySizes.find(
          (item) => item.id === data.codPorte
        );
        setSelected([selectedCompanySize.id]);
      })
      .catch((err) => console.warn(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCompaniesSizes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calcCNG();
    // eslint-disable-next-line
  }, [form.fampe]);

  return loading ? (
    <Loading />
  ) : (
    <div className="step guarantees">
      <BaSeHeading5
        color="#005EB8"
        fontFamily="Alegreya Sans"
        isBold
        className="mt-0"
      >
        FAMPE
      </BaSeHeading5>
      <BaSeGrid>
        <BaSeGridItem
          desktop={{
            size: 9,
            startAt: undefined,
          }}
          desktopLarge={{
            size: 8,
            startAt: undefined,
          }}
        >
          <p>
            O FAMPE é o fundo de aval do Sebrae que existe para te ajudar a
            completar o valor exigido pelo banco como garantia para o
            financiamento pretendido.
          </p>
          <p>
            Verifique os Limites de Garantia do FAMPE por Porte e Modalidade e
            conheça mais sobre o FAMPE no Portal Sebrae.
          </p>

          <Formik initialValues={form} onSubmit={() => {}}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="wrapper-select">
                  <BaSeOptionPicker
                    label="Porte da empresa"
                    values={companySizes}
                    enableSelectAll={false}
                    menuAlwaysIsOpen={false}
                    withFilterText={false}
                    emptyValueLabel="Selecione"
                    maxOptionsSelected={1}
                    initialSelectedValuesId={selected}
                    onChange={(values) => {
                      setSelected(values.map((val) => val.id));
                    }}
                  />
                </div>
                <div className="group-input">
                  <BaSeGrid>
                    <BaSeGridItem
                      desktop={{
                        size: 6,
                        startAt: undefined,
                      }}
                    >
                      <BaSeInput
                        color="default"
                        inputMode="tel"
                        typeInput="tel"
                        inputStatus={
                          validateCurrency(form.f) ? "invalid" : "valid"
                        }
                        value={form.f}
                        onChange={(e) => {
                          setForm({ ...form, f: e });
                        }}
                        onBlur={() => calcFampe()}
                        placeholder="R$"
                        maxLength={22}
                        prefix="R$ "
                        mask="decimal"
                        size="medium"
                        label="Valor Financiamento"
                        width="100%"
                      />
                    </BaSeGridItem>
                    <BaSeGridItem
                      desktop={{
                        size: 6,
                        startAt: undefined,
                      }}
                    >
                      <BaSeInput
                        color="default"
                        inputMode="decimal"
                        typeInput="tel"
                        inputStatus={
                          validateCurrency(form.geb) ? "invalid" : "valid"
                        }
                        value={form.geb}
                        onChange={(e) => {
                          setForm({ ...form, geb: e });
                        }}
                        onBlur={() => calcCNG()}
                        placeholder="R$"
                        maxLength={22}
                        prefix="R$ "
                        mask="decimal"
                        size="medium"
                        label="Valor garantias exigidas pelo banco"
                        width="100%"
                      />
                    </BaSeGridItem>
                  </BaSeGrid>
                </div>
                <div className="group-input inputs-blue border-none mb-0">
                  <BaSeGrid>
                    <BaSeGridItem
                      desktop={{
                        size: 6,
                        startAt: undefined,
                      }}
                    >
                      <div
                        className={
                          "input-error-message " +
                          (form.fampe &&
                          (validateCurrency(form.fampe) ||
                            formatFloat(form.fampe) > getCeiling())
                            ? "invalid"
                            : "")
                        }
                      >
                        <BaSeInput
                          key={form.fampe}
                          color="default"
                          inputMode="tel"
                          typeInput="tel"
                          value={formatCurrency(
                            Math.min(formatFloat(form.fampe), getCeiling())
                          )}
                          placeholder="R$"
                          maxLength={22}
                          prefix="R$ "
                          mask="decimal"
                          size="medium"
                          label="Garantia Fampe"
                          width="100%"
                          isReadOnly={true}
                          showHelpButton
                          helpButtonProps={{
                            direction: "top",
                            alertSize: 288,
                            iconProps: {
                              color:
                                validateCurrency(form.fampe) ||
                                formatFloat(form.fampe) > getCeiling()
                                  ? "#BF303C"
                                  : "#005eb8",
                              size: 16,
                            },
                            title: "FAMPE",
                            message: [
                              "O FAMPE reduz a necessidade de garantias próprias exigidas pela Instituição Financeira em até 80%.",
                              "\n\n• MEI: Teto = R$ 100.000,00",
                              "\n• ME: Teto = R$ 400.000,00",
                              "\n• EPP: Teto = R$ 700.000,00",
                            ],
                            type: "popover",
                          }}
                        />
                        <span className="error-message">
                          O limite de R$ {formatCurrency(getCeiling())} por
                          operação para o porte selecionado foi atingido.
                        </span>
                      </div>
                    </BaSeGridItem>
                    <BaSeGridItem
                      desktop={{
                        size: 6,
                        startAt: undefined,
                      }}
                    >
                      <BaSeInput
                        key={form.cng}
                        color="default"
                        inputMode="tel"
                        typeInput="tel"
                        inputStatus={
                          validateCurrency(form.cng) ? "invalid" : "valid"
                        }
                        value={form.cng}
                        placeholder="R$"
                        maxLength={22}
                        prefix="R$ "
                        mask="decimal"
                        size="medium"
                        label="Complemento necessário de garantias"
                        width="100%"
                        isReadOnly={true}
                      />
                    </BaSeGridItem>
                  </BaSeGrid>
                </div>

                <div className="planning__footer">
                  <BaSeButton
                    type="secondary"
                    buttonType="button"
                    onClick={() => backStep()}
                    color="#005eb8"
                    size="medium"
                    value="Anterior"
                    width="87px"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </BaSeGridItem>
        <BaSeGridItem
          desktop={{
            size: 3,
            startAt: undefined,
          }}
          desktopLarge={{
            size: 4,
            startAt: undefined,
          }}
        >
          <div className="box-fampe">
            <p>
              Saiba mais sobre o FAMPE{" "}
              <a
                target="_blank"
                href="https://sebrae.com.br/sites/PortalSebrae/fampe"
                rel="noreferrer"
              >
                aqui
              </a>
            </p>
            <figure>
              <img src={logoFampe} alt="FAMPE" loading="lazy" />
            </figure>
          </div>
        </BaSeGridItem>
      </BaSeGrid>
    </div>
  );
};

export default Guarantees;
