import {BaSeButton, BaSeHeading5, BaSeInput} from "@base/react";
import {Form, Formik} from "formik";
import {validateIsRequired} from "../../../services/validate";
import {useCallback, useEffect, useState} from "react";

const NamePlanning = ({dataPlanning, sendForm}) => {
  const [form, setForm] = useState({
    title: dataPlanning?.title || ""
  })
  const [isInvalid, setIsInvalid] = useState(false)

  const checkValidForm = useCallback(() => {
    let invalid = false
    if (!invalid) {
      // eslint-disable-next-line no-unused-vars
      for (const [k, value] of Object.entries(form)) {
        invalid = validateIsRequired(value)
      }
    }
    setIsInvalid(invalid)
  }, [form])
  const submitForm = () => {
    sendForm(form)
  }

  useEffect(() => {
    checkValidForm()
  })

  return (
    <div className="step name-planning">
      <BaSeHeading5 color="#005EB8" fontFamily="Alegreya Sans" isBold className="mt-0">
        Novo Planejamento
      </BaSeHeading5>
      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <BaSeInput
              isDisabled={dataPlanning?.is_finished}
              color="default"
              inputMode="text"
              typeInput="text"
              inputStatus={validateIsRequired(form.title) ? "invalid" : "valid"}
              value={form.title}
              onInput={(e) => {setForm({...form, title: e.target.value })}}
              placeholder="Escolha um nome para esse planejamento"
              size="medium"
              label="Nome do planejamento"
              subLabel="(Campo obrigatório)"
              width="100%"
            />

            <div className="planning__footer start">
              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NamePlanning