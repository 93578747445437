import "./index.scss";

const Loading = ({ fullPage = false, small = false }) => {
  return (
    <div
      className={`wrapper-loading ${fullPage ? "full" : ""} ${
        small ? "small" : ""
      }`}
    >
      <div className="loading">
        <div />
      </div>
    </div>
  );
};

export default Loading;
