import "./index.scss";
import { BaSeIcon } from "@base/react";
import { useEffect, useState } from "react";

const ModalDefault = ({
  show,
  title,
  children,
  callBackClose,
  square = false,
}) => {
  const [showModal, setShowModal] = useState(show);
  const closeModal = () => {
    setShowModal(false);
    if (callBackClose) callBackClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  useEffect(() => {
    showModal
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "");
  }, [showModal]);

  return (
    showModal && (
      <div className="modal-default">
        <div onClick={() => closeModal()} className="close-page"></div>
        <div className={`wrapper-modal ${square ? "square" : ""}`}>
          <button className="btn-close" onClick={() => closeModal()}>
            <BaSeIcon
              color="#005EB8"
              description="Close"
              name="close"
              size={22}
            />
          </button>
          <div className="content">
            {title && (
              <div className="modal-header">
                <h3 className={`title ${square ? "square-title" : ""} `}>
                  {title}
                </h3>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default ModalDefault;
