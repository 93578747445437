import { createContext, useState } from "react";

const companiesData = JSON.parse(localStorage.getItem("companies")) || [];
export const CompaniesContext = createContext(companiesData);

export const CompaniesProvider = ({ children }) => {
  const [companies, setCompanies] = useState(companiesData);

  const toggleCompanies = (cnpj) => {
    const updatedData = companies.map((el) => ({
      ...el,
      isActive: el.cnpj === cnpj,
    }));

    localStorage.setItem("companies", JSON.stringify(updatedData));
    setCompanies(updatedData);
  };

  return (
    <CompaniesContext.Provider value={{ companies, toggleCompanies }}>
      {children}
    </CompaniesContext.Provider>
  );
};
