import Onboarding from "../../components/onboarding";
import { useNavigate } from "react-router-dom";

const Instructions = ({ account }) => {
  const navigate = useNavigate();
  return (
    <Onboarding
      typePage
      setHideOnboarding={() => {}}
      callBack={() => navigate("/")}
    />
  );
};

export default Instructions;
