import {BaSeButton, BaSeGrid, BaSeGridItem, BaSeIcon} from "@base/react";
import {useCallback, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import TitleWithPopover from "../../../components/title-with-popover";
import RouterPrompt from "../../../components/router-prompt";
import InputRange from "../../../components/input-range";
import ProgressBar from "../../../components/progress-bar";
import {formatFloat, sortedArrayByObjectKeys, sumValuesPercent} from "../../../services/utils";
import {toast} from "react-toastify";
import ToastContent from "../../../components/toastContent";
import iconError from "../../../assets/images/icons/exclamation-octagon.png";

const ReceiptDeadline = ({dataPlanning, sendForm, backStep}) => {
  const structureData = ( dataPlanning?.data?.pdr && dataPlanning?.data?.pdr.length ? sortedArrayByObjectKeys(dataPlanning?.data?.pdr) : false) || [
    {order: 0, value: 0},
    {order: 1, value: 0},
    {order: 2, value: 0},
    {order: 3, value: 0},
    {order: 4, value: 0},
    {order: 5, value: 0},
  ]
  const [form, setForm] = useState(structureData)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false)
  const checkValidForm = useCallback(() => {
    let invalid = false
    if (!invalid) {
      invalid = sumValuesPercent(form) !== 100
    }
    setIsInvalid(invalid)
  }, [form])

  const handleChange = (order, newValue) => {
    setForm((prevFields) =>
      prevFields.map((field) => {
        return (
          field.order === order ? { ...field, value: formatFloat(newValue) } : field
        )
      })
    );
  };
  const addField = () => {
    setForm((prevFields) => [
      ...prevFields,
      {order: prevFields.length, value: 0},
    ]);
  };
  const fnSave = () => {
    const totalSum = sumValuesPercent(form)
    if (totalSum === 100) {
      submitForm(false)
    } else {
      toast.error(
        <ToastContent
          title="Não é possível salvar"
          description={totalSum < 100 ? "Para salvar, é necessário que a soma dos percentuais lançados seja IGUAL a 100%." : "A soma dos percentuais lançados ultrapassa o limite de 100%."}
        />,
        {
          icon: () => <img src={iconError} alt="icon" />,
        },
      );
    }
  }
  const fnOnBlur = () => {
    if (sumValuesPercent(form) === 100) submitForm(false, true)
  }
  const submitForm = (nextStep = true, fnBlur = false) => {
    sendForm({
      ...dataPlanning,
      data: {
        ...dataPlanning.data,
        pdr: form
      }
    }, nextStep, fnBlur)
    setHasUnsavedChanges(false)
  }

  useEffect(() => {
    checkValidForm()
  })

  return (
    <div className="step receipt-deadline">
      <TitleWithPopover title="Prazos de Recebimento"
                        titlePopover="% da Receita"
                        messagePopover="A soma dos percentuais lançados DEVE SER IGUAL a 100%." size={5} />

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="group-input desktop">
              <BaSeGrid>
                {
                  form.slice(0,2).map(({order, value}) => {
                    let label = order === 0 ? "Recebimento à vista" : "Recebimento em 30 dias"

                    return (
                      <BaSeGridItem key={order} desktop={{
                        size: 6,
                        startAt: undefined
                      }}>
                        <div className="group-input mobile">
                          <InputRange
                            isDisabled={dataPlanning?.is_finished}
                            label={label}
                            value={value}
                            onBlur={() => fnOnBlur()}
                            setValue={(e) => {
                              handleChange(order, e)
                            }}/>
                        </div>
                      </BaSeGridItem>
                    )
                  })
                }
              </BaSeGrid>
            </div>

            <div className="installments">
              <BaSeGrid>
                {
                  form.slice(2, form.length).map(({order, value}) => {
                    let label = `Recebimento em ${order}x`

                    return (
                      <BaSeGridItem key={order} desktop={{
                        size: 6,
                        startAt: undefined
                      }}>
                        <InputRange
                          isDisabled={dataPlanning?.is_finished}
                          label={label}
                          value={value}
                          onBlur={() => fnOnBlur()}
                          setValue={(e) => {
                            handleChange(order, e)
                          }}/>
                      </BaSeGridItem>
                    )
                  })
                }
              </BaSeGrid>

              {
                form.length <= 12 && !dataPlanning?.is_finished ? (
                  <button type="button" className="btn-without-style btn-add-more" onClick={addField}>
                    <BaSeIcon
                      color="#005EB8"
                      description="Adicionar"
                      name="plus-square"
                      size={24}
                    />
                  </button>
                ) : ""
              }
            </div>

            <ProgressBar value={sumValuesPercent(form)} />

            {
              !dataPlanning?.is_finished ? (<div className="wrapper-button-secondary">
                <BaSeButton
                  buttonType="button"
                  color="#005EB8"
                  isBold
                  onClick={() => {
                    fnSave()
                  }}
                  rightIcon="save"
                  size="small"
                  type="secondary"
                  value="Salvar"
                />
              </div>) : ""
            }

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep(hasUnsavedChanges)}
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                isDisabled={isInvalid}
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>

      <RouterPrompt initialValue={structureData} newValue={form} setHasUnsavedChanges={setHasUnsavedChanges}/>
    </div>
  )
}

export default ReceiptDeadline