import React from "react";
import "./index.scss";

const StepComponent = ({ stepActive, filterType, listSteps }) => {
  return (
    <div className="step-wrapper">
      {listSteps.map((step, index) => (
        <button
          key={index}
          type="button"
          className={stepActive === index ? "active" : ""}
          onClick={() => filterType(step.action, index)}
        >
          {step.label}
        </button>
      ))}
    </div>
  );
};

export default StepComponent;
