import {convertToSlug} from "../../services/utils";
import './index.scss'

const Textarea = ({
                    label = "",
                    id = convertToSlug(label) || "customTextarea", name,
                    value = '', setValue,
                    onBlur, isDisabled = false
                  }) => {
  return (
    <div className="custom-textarea">
      <div className="input-textarea">
        <label htmlFor={id} className="input-textarea-label">{label}</label>
        <textarea
          className="input-textarea-textarea"
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          disabled={isDisabled}
          placeholder="Descreva a justificativa para aumento geral das quantidades vendidas"
          id={id} />
      </div>
    </div>
  )
}

export default Textarea