import "./index.scss";
import Slider from "react-slick";
import { Bar } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MyChart = ({ result, period, slider = true }) => {
  let balance = [];
  let dateChart = [];
  const listMont = [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ];

  if (result && Array.isArray(result.fluxgraph)) {
    const saldo = result.fluxgraph.map((item) => item.capacidadeMensal);

    const dates = result.fluxgraph.map((item) => {
      const month = item.mes.split("-")[1];
      const year = item.mes.split("-")[0];
      return `${listMont[parseInt(month) - 1]}-${year.slice(2, 5)}`;
    });

    balance = saldo;
    dateChart = dates;
  } else {
    balance = [];
    dateChart = [];
  }

  const [chunkSize, setChunkSize] = useState(15);

  useEffect(() => {
    const updateChunkSize = () => {
      setChunkSize(window.innerWidth <= 768 ? 10 : 20);
    };

    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);

    return () => {
      window.removeEventListener("resize", updateChunkSize);
    };
  }, []);

  const totalChunks = Math.ceil(dateChart.length / chunkSize);

  const getChunkData = (chunkIndex) => {
    const start = chunkIndex * chunkSize;
    const end = start + chunkSize;

    return {
      labels: dateChart.slice(start, period && period.length ? period[0] : end),
      dataValues: balance.slice(
        start,
        period && period.length ? period[0] : end
      ),
    };
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        ticks: {
          color: "#005EB8",
          maxRotation: 90,
          minRotation: 90,
        },
        grid: { display: false },
      },
      y: {
        ticks: {
          color: "#005EB8",
          callback: (val) => {
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          },
        },
        grid: { color: "rgba(148, 252, 204, 0.2)" },
      },
    },
    layout: { padding: 10 },
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="chart-wrapper">
      {slider ? (
        <Slider {...sliderSettings}>
          {Array.from({
            length: period && period.length ? 1 : totalChunks,
          }).map((q, chunkIndex) => {
            const { labels, dataValues } = getChunkData(chunkIndex);
            const data = {
              labels,
              datasets: [
                {
                  label: "Variação",
                  data: dataValues,
                  backgroundColor: dataValues.map((value) =>
                    value >= 0 ? "#14ADCC" : "#F4455A"
                  ),
                },
              ],
            };

            return (
              <div key={chunkIndex}>
                <Bar data={data} options={options} />
              </div>
            );
          })}
        </Slider>
      ) : (
        <div className="chart-grid">
          {Array.from({
            length: period && period.length ? 1 : totalChunks,
          }).map((q, chunkIndex) => {
            const { labels, dataValues } = getChunkData(chunkIndex);
            const data = {
              labels,
              datasets: [
                {
                  label: "Variação",
                  data: dataValues,
                  backgroundColor: dataValues.map((value) =>
                    value >= 0 ? "#14ADCC" : "#F4455A"
                  ),
                },
              ],
            };

            return (
              <div key={chunkIndex} style={{ margin: "20px" }}>
                <Bar data={data} options={options} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MyChart;
