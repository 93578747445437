import "./index.scss";
import { BaSeHeading5 } from "@base/react";
import moment from "moment";
import Loading from "../loading";
import { useEffect, useState } from "react";

const CardTrash = ({ data, idItem, callback }) => {
  const [id, setId] = useState("");

  useEffect(() => {
    setId(idItem);
  }, [idItem]);

  return (
    <div className={`list-trash ${id === data.id ? "restoring" : ""}`}>
      <div className="title">
        <BaSeHeading5>{data.title}</BaSeHeading5>
        <p>(Excluído em {moment(data.date).format("DD/MM/YYYY")})</p>
      </div>
      {id === data.id ? <Loading small={true} /> : null}
      <button
        type="button"
        onClick={() => {
          if (callback) callback(data.id);
        }}
      >
        Restaurar
      </button>
    </div>
  );
};

export default CardTrash;
