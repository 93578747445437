import axios from "axios";
import { getToken } from "./utils";

const createInstance = () => {
  const access = getToken();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
  });

  if (access) {
    instance.interceptors.request.use(
      async (config) => {
        config.headers = {
          ...config.headers,
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        };
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  return instance;
};

export default createInstance;
