const ToastContent = ({title, description}) => {
  return (
    <div className="toast-content">
      {
        title ? (<h3>{title}</h3>) : ""
      }
      {
        description ? (<p dangerouslySetInnerHTML={{ __html: description }} />) : ""
      }
    </div>
  )
}

export default ToastContent