import Header from "./header";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Onboarding from "../onboarding";
import ApiAuth from "../../services/auth";

export default function Layout({ children, ...props }) {
  const { pathname } = useLocation();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { account } = { ...props };
  const onboarding = JSON.parse(localStorage.getItem("onboarding")) || "";

  const closeOnboarding = () => {
    ApiAuth()
      .finishOnboarding()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        localStorage.setItem("onboarding", JSON.parse("false"));
        setShowOnboarding(false);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  useEffect(() => {
    if (!!account) {
      if (localStorage.getItem("onboarding") === "true") {
        ApiAuth()
          .skipOnboarding()
          .then((r) => {
            const { data } = r;
            if (data.onboarding) {
              localStorage.setItem("onboarding", JSON.parse("false"));
            }
            setShowOnboarding(!data.onboarding);
          })
          .catch((e) => {
            console.error(e);
            setShowOnboarding(!account?.unauthorized);
          });
      }
    }
  }, [account]);
  useEffect(() => {
    if (showOnboarding && onboarding) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    // eslint-disable-next-line
  }, [showOnboarding]);
  return (
    <div className="app">
      {account && !account?.unauthorized ? <Header {...props} /> : ""}
      <main>
        {showOnboarding && onboarding ? (
          <Onboarding
            callBack={() => closeOnboarding()}
            setHideOnboarding={() => {
              setShowOnboarding(false);
            }}
          />
        ) : (
          ""
        )}
        {children}
      </main>
    </div>
  );
}
