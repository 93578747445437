"use client";
import "./button.scss";
const Button = ({
  type = "button",
  title,
  callback,
  style = "btn",
  children,
}) => {
  return (
    <button
      type={type}
      className={style}
      onClick={() => (callback ? callback() : null)}
    >
      {title}
      {children}
    </button>
  );
};

export default Button;
