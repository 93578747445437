import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaSeButton, BaSeCheckbox } from "@base/react";
import { BaSeHeading3, BaSeParagraph } from "@base/react";
import { CompaniesContext } from "../../context/companies";
import "./index.scss";
import ApiOptIn from "../../services/optIn";
import Loading from "../../components/loading";
import CardInputCompany from "../../components/cards/card-input-company";

const Home = () => {
  const ApiOpt = ApiOptIn();
  const navigate = useNavigate();
  const location = window.location.host;
  const protocol = window.location.protocol;
  const { companies, toggleCompanies } = useContext(CompaniesContext);
  const [optIn, setOptIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userWithCpf, setUserWithCpf] = useState(false);
  const [selectedAgree, setSelectedAgree] = useState(false);

  const handleRegisterCompany = () => {
    window.location.href = `${process.env.REACT_APP_CPE}/?voltar=${protocol}//${location}/`;
  };

  const getOpt = () => {
    setLoading(true);
    ApiOpt.getOptIn()
      .then((response) => {
        const { data } = response;
        setOptIn(data.opt_in);
      })
      .catch((error) => {
        console.warn("ERROR: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const postOpt = (page) => {
    ApiOpt.postOptIn()
      .catch((err) => console.warn(err))
      .finally(() => {
        setOptIn(true);
        navigate(page);
      });
  };

  const handleCheckboxAgree = (checked) => {
    setSelectedAgree(checked);
  };

  const handleCheckboxCpf = (checked) => {
    setUserWithCpf(checked);
  };

  const createNewPlanning = () => {
    navigate(`/planejadora-sebrae`);
  };

  const newPlanningWithCPF = () => {
    if (optIn === false) {
      postOpt("/planejamento");
    } else {
      navigate("/planejamento");
    }
  };

  const myPlannings = () => {
    if (optIn === false) {
      postOpt("/meus-planejamentos");
    } else {
      navigate("/meus-planejamentos");
    }
  };

  useEffect(() => {
    getOpt();
  }, []);

  if (loading) {
    return <Loading fullPage />;
  }

  return (
    <div className="container">
      <div className="related-companies-container">
        <BaSeHeading3 color="#005EB8" fontFamily="Alegreya Sans" isBold>
          Olá empresário!
        </BaSeHeading3>
        <BaSeParagraph className="top-text">
          {companies.length
            ? "Essas são as empresas vinculadas ao seu CPF. Selecione uma para começar a utilizar a calculadora:"
            : "Não identificamos empresas vinculadas ao seu CPF. Cadastre a sua empresa ou continue com o seu CPF."}
        </BaSeParagraph>
        {companies.length ? (
          <div className="available-companies">
            {companies.map((company, k) => (
              <CardInputCompany
                key={k}
                data={company}
                callBack={(e) => toggleCompanies(e)}
                selected={company.isActive}
              />
            ))}
          </div>
        ) : (
          <div className="company__register__area">
            <div className="company__register__area__button">
              <BaSeButton
                value="Cadastrar minha empresa"
                onClick={handleRegisterCompany}
              />
            </div>
            <div className="company__register__area__motto">
              <div className="line" />
              <BaSeParagraph className="top-text">ou</BaSeParagraph>
              <div className="line" />
            </div>
            <BaSeCheckbox
              label="Continuar com o meu CPF"
              id="user_cpf"
              name="user_cpf"
              checked={userWithCpf}
              onChange={handleCheckboxCpf}
            />
          </div>
        )}

        <div className="company__bottom__content">
          <div className="read-terms">
            {!optIn && (
              <div className="read-terms">
                <BaSeCheckbox
                  label={
                    <span>
                      Declaro que li e concordo com os{" "}
                      <a
                        href="https://calc-back.valide.vc/media/termo_de_uso_planejadora%20_sebrae.pdf"
                        className="underlined-term"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Termos de Uso.
                      </a>
                    </span>
                  }
                  id="company_read_and_agree"
                  name="read_and_agree"
                  checked={selectedAgree}
                  onChange={handleCheckboxAgree}
                />
              </div>
            )}
          </div>
          <div className="company__button">
            {companies.length ? (
              <div className="company__button-continue">
                <BaSeButton
                  isDisabled={
                    !companies.some((company) => company.isActive) ||
                    !(optIn || selectedAgree)
                  }
                  onClick={createNewPlanning}
                  value="Continuar"
                />
              </div>
            ) : (
              <>
                <BaSeButton
                  isDisabled={!(optIn || selectedAgree) || !userWithCpf}
                  onClick={newPlanningWithCPF}
                  value="Novo planejamento"
                />
                <BaSeButton
                  isDisabled={!(optIn || selectedAgree) || !userWithCpf}
                  onClick={myPlannings}
                  value="Meus planejamentos"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
