import Dialog from "../dialog";
import {useBlocker} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {isNotEqual} from "../../services/utils";

const useRouterPrompt = (when = false) => {

  const blocker = useBlocker(
    ({ currentLocation, nextLocation, historyAction }) => {
      return when && currentLocation.pathname !== nextLocation.pathname
    }
  )

  return { isPrompting: blocker.state === "blocked", block: blocker.reset, navigate: blocker.proceed }
}

const RouterPrompt = ({
                        initialValue,
                        newValue,
                        setHasUnsavedChanges,
                        forceShowDialog = false,
                        callbackCancel, callbackConfirm,
                        description = "Para prosseguir com o cálculo do seu planejamento, a soma dos percentuais informados deve ser igual a 100%."
                      }) => {
  const hasUnsavedChanges = useMemo(() => isNotEqual(initialValue, newValue), [initialValue, newValue])
  const { isPrompting, block, navigate } = useRouterPrompt(hasUnsavedChanges)

  useEffect(() => {
    if (typeof setHasUnsavedChanges === "function") {
      setHasUnsavedChanges(hasUnsavedChanges)
    }
  }, [hasUnsavedChanges, setHasUnsavedChanges])

  return (
    <Dialog show={forceShowDialog || isPrompting}
            title="Tem certeza de que deseja sair?"
            description={description}
            btnCancel="Continuar Editando"
            btnConfirm="Confirmar Saída"
            onHide={forceShowDialog ? callbackCancel : block}
            callbackCancel={forceShowDialog ? callbackCancel : block}
            callbackConfirm={callbackConfirm ? callbackConfirm : navigate}
    />
  )
}

export default RouterPrompt