import React, {useEffect, useState} from "react";
import {
  BaSeButton,
  BaSeDatePicker,
  BaSeHeading6,
  BaSeIcon,
  BaSeInput,
  BaSeOptionPicker,
  BaSeParagraph,
  BaSePopupButton,
} from "@base/react";
import {formatCurrency, formatFloat, getDateTomorrow,} from "../../../services/utils";
import {Form, Formik} from "formik";
import {validatePercentage} from "../../../services/validate";
import TipBlock from "../../../components/tip-block";
import logoTrophy from "../../../assets/images/trophy.svg";
import logoResult from "../../../assets/images/logo-result.svg";
import logoMonthlyCapacity from "../../../assets/images/monthly-capacity-logo.svg";
import MyChart from "../../../components/chart";
import ModalDefault from "../../../components/modals/modal-default";
import Textarea from "../../../components/textarea";
import moment from "moment";

const popoverSold = {
  direction: "left",
  alertSize: 255,
  iconProps: {
    color: "#243342",
    size: 16,
  },
  title: "Aumento geral das quantidades vendidas",
  message: (
    <span
      dangerouslySetInnerHTML={{
        __html: `Ao inserir um valor percentual neste campo, você simula uma variação geral nas quantidades vendidas, impactando os indicadores de resultado apresentados neste painel.

Use esta ferramenta para ajustar as vendas percentualmente e explorar cenários financeiros variados. Observe como todos os indicadores são afetados, permitindo que você planeje estrategicamente.

Essa simulação pode orientar suas ações empreendedoras, ajudando a definir metas alcançáveis por meio do aumento das vendas <strong>(RECEITA TOTAL)</strong>.

Por exemplo, é possível calcular o aumento nas vendas necessário para atingir uma meta específica de lucro.
  `,
      }}
    />
  ),
  type: "popover",
};
const popoverPrices = {
  direction: "left",
  alertSize: 255,
  iconProps: {
    color: "#243342",
    size: 16,
  },
  title: "Valor investimento fixo",
  message: (
    <span
      dangerouslySetInnerHTML={{
        __html: `
Ao inserir um valor percentual neste campo, você simula uma variação geral nos preços de venda, impactando os indicadores de resultado deste painel.

Use este campo para ajustar percentualmente os preços de venda e explorar diferentes cenários financeiros. Observe como todos os indicadores são atualizados, permitindo um planejamento estratégico.

Essas simulações ajudam a definir metas viáveis por meio do ajuste dos preços de venda.

Por exemplo, você pode calcular o aumento médio nos preços necessário para atingir uma meta específica de lucro. Contudo, lembre-se de que os preços praticados são fortemente influenciados por fatores de mercado.

Para fundamentar seu planejamento financeiro, observe o mercado e os preços de produtos e/ou serviços similares ou substitutos. Acompanhe a concorrência e ajuste as estratégias conforme necessárias.

<strong>Nota</strong>: Este campo ajusta apenas os preços de venda dos produtos e/ou serviços comercializados pela empresa, não suas quantidades.`,
      }}
    />
  ),
  type: "popover",
};
const workingCapital = `
Lembre-se: você pode formar seu Capital de Giro com recursos próprios ou financiados. <br /> <br/>

Este campo indica o montante necessário para sustentar as operações da empresa. <br /> <br/>

<strong>Atenção</strong>: Ajustes nos percentuais de aumento dos preços de venda ou das quantidades vendidas impactam diretamente esse valor, alterando a necessidade total de capital de giro.
`;

const goalPlan = `
  Encare o seu planejamento como uma meta a ser alcançada. <br /> <br />
  Para isso, preencha este campo com a data na qual você estima que o seu negócio terá alcançado os resultados projetados neste plano.
`;
const periods = () => {
  const periods = [];
  const maxPeriods = 120;

  for (let p = 1; p <= maxPeriods; p++) {
    periods.push({
      id: p,
      label: `${p} ${p > 1 ? "meses" : "mês"}`,
    });
  }

  return periods;
};

const Results = ({
  dataPlanning,
  sendForm,
  backStep,
  downloadPlanning,
  openDialogFinish,
}) => {
  const structureData = {
    VGdQV: formatCurrency(dataPlanning?.data?.VGdQV) || 0,
    VGdPV: formatCurrency(dataPlanning?.data?.VGdPV) || 0,
    VGdQV_just: dataPlanning?.data?.VGdQV_just || "",
    VGdPV_just: dataPlanning?.data?.VGdPV_just || "",
    goal_date: dataPlanning?.data?.goal_date
      ? new Date(dataPlanning?.data?.goal_date)
      : null,
  };

  const fnOnBlur = () => {
    submitForm(false, true);
  };

  const [form, setForm] = useState(structureData);
  const [currentPeriod, setCurrentPeriod] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalOnSubmit, setShowModalOnSubmit] = useState(false);

  const handleOnSubmit = () => {
    if (!form.goal_date) {
      setShowModalOnSubmit(true);
      return;
    }

    dataPlanning?.is_finished ? downloadPlanning() : openDialogFinish();
  };
  const submitForm = (nextStep = true, fnBlur = false) => {
    sendForm(
      {
        ...dataPlanning,
        data: {
          ...dataPlanning.data,
          VGdQV: formatFloat(form.VGdQV),
          VGdPV: formatFloat(form.VGdPV),
          VGdQV_just: form.VGdQV_just,
          VGdPV_just: form.VGdPV_just,
          goal_date: form.goal_date,
        },
      },
      nextStep,
      fnBlur
    );
  };

  useEffect(() => {
    if (
      Object.values(dataPlanning?.results?.fluxgraph).length === 0 &&
      dataPlanning?.is_finished === false
    ) {
      setShowModal(true);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (form.goal_date !== structureData.goal_date) fnOnBlur()
    // eslint-disable-next-line
  }, [form.goal_date])

  return (
    <div className="step results">
      <TipBlock
        image={logoResult}
        borderColor="linear-gradient(to top, #84F4BC, #40BBFF)"
      >
        <BaSeHeading6 color="#005EB8" isBold>
          Resultados
        </BaSeHeading6>
      </TipBlock>

      <ModalDefault
        show={showModal}
        callBackClose={() => setShowModal(false)}
        title="Atenção! Notas técnicas"
        square
      >
        <div className="notes-modal">
          <BaSeParagraph>
            <span>NOTA TÉCNICA 1:</span> <br />
            Se os dados lançados nesta calculadora estiverem incoerentes com a
            realidade da empresa, o planejamento elaborado não deverá ser
            utilizado para basear decisões estratégicas e nem balizar as
            negociações relacionadas a contratação de crédito.
          </BaSeParagraph>
          <br />
          <BaSeParagraph>
            <span>NOTA TÉCNICA 2:</span> <br />O dimensionamento da Necessidade
            de Capital de Giro, contido nesta calculadora, visa fundamentar
            decisões sobre política de prazos e investimentos em capital de giro
            e utiliza estimativas para valores como vendas, prazos, custos e
            estoque. Se estas estimativas estiverem incoerentes com a realidade
            da empresa no mercado, a Necessidade de Capital de Giro, apresentada
            na tela denominada Resultado, não representará, necessariamente, o
            valor a ser pleiteado como crédito em uma instituição financeira
            (banco).
          </BaSeParagraph>
          <br />
          <BaSeParagraph>
            <span>NOTA TÉCNICA 3:</span> <br />O método de dimensionamento da
            Necessidade de Capital de Giro, contido nesta calculadora tem,
            fundamentalmente, caráter orientativo. Visa ampliar a compreensão
            sobre as finanças da empresa e possibilita realizar simulações que
            demostrem como a Necessidade de Capital de Giro muda de valor quando
            variamos as vendas ou os prazos médios de recebimento, de pagamento
            e das mercadorias em estoque.
          </BaSeParagraph>
          <br />
          <BaSeButton
            value="Ok"
            onClick={() => setShowModal(false)}
            width="71px"
          />
        </div>
      </ModalDefault>

      <ModalDefault
        callBackClose={() => setShowModalOnSubmit(false)}
        show={showModalOnSubmit}
        title="Estabeleça sua meta!"
      >
        <BaSeParagraph>
          Para finalizar e baixar o planejamento, por favor preencha a Meta do
          planejamento na tela de <strong> Resultados.</strong>
        </BaSeParagraph>{" "}
        <br />
        <BaSeButton
          value="Ok"
          onClick={() => setShowModalOnSubmit(false)}
          width="71px"
        />
      </ModalDefault>

      <BaSeHeading6 className="title" color="#005EB8" isBold>
        Resultado - {moment().format("MMMM YYYY")}
      </BaSeHeading6>

      {dataPlanning?.results ? (
        <>
          <div className="results-information">
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>Receita Total (RT)</BaSeParagraph>
              </span>
              <span className="results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.rt)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>Custo Total (CT)</BaSeParagraph>
              </span>
              <span className="results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.ct)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>Lucro (L)</BaSeParagraph>
              </span>
              <span className="results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.l === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.l > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  R$ {formatCurrency(dataPlanning?.results?.l)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Lucratividade (%LT)
                </BaSeParagraph>
              </span>
              <span className="results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.lt === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.lt > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  {formatCurrency(dataPlanning?.results?.lt)}%
                </BaSeParagraph>
              </span>
            </div>
          </div>
          <BaSeHeading6 className="title" color="#005EB8" isBold>
            Investimento
          </BaSeHeading6>

          <div className="results-information">
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>Investimento Financeiro</BaSeParagraph>
              </span>
              <span className="results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.infin)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>Investimento Total</BaSeParagraph>
              </span>
              <span className="results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.intot)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Rentabilidade (mensal)
                </BaSeParagraph>
              </span>
              <span className="results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.rtm === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.rtm > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  {formatCurrency(dataPlanning?.results?.rtm)}%
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Prazo de Retorno de Investimentos (anual)
                </BaSeParagraph>
              </span>
              <span className="results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.pri === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.pri > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  {formatCurrency(dataPlanning?.results?.pri)}
                </BaSeParagraph>
              </span>
            </div>
          </div>

          <BaSeHeading6 className="title" color="#005EB8" isBold>
            Capital de giro
          </BaSeHeading6>

          <div className="results-information">
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>
                  Necessidade de Capital de Giro
                </BaSeParagraph>
              </span>
              <span className="results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.ncg)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph isBold>Capital de giro próprio</BaSeParagraph>
              </span>
              <span className="results-information__value">
                <BaSeParagraph>
                  R$ {formatCurrency(dataPlanning?.results?.cgp)}
                </BaSeParagraph>
              </span>
            </div>
            <div className="results-information__data">
              <span className="results-information__info">
                <BaSeParagraph color="#005EB8" isBold>
                  Necessidade de Formação de Capital de Giro
                </BaSeParagraph>
                <BaSePopupButton
                  title="Necessidade de Formação de Capital de Giro"
                  type="popover"
                  direction="bottom"
                  message={
                    <span
                      dangerouslySetInnerHTML={{ __html: workingCapital }}
                    />
                  }
                  alertSize={315}
                />
              </span>
              <span className="results-information__value highlighted">
                <BaSeParagraph
                  color={
                    dataPlanning?.results?.nfcg === 0
                      ? "#005EB8"
                      : dataPlanning?.results?.nfcg > 0
                      ? "#008000"
                      : "#BF303C"
                  }
                  isBold
                >
                  R${" "}
                  {formatCurrency(
                    dataPlanning?.results?.nfcg >= 0
                      ? dataPlanning?.results?.nfcg
                      : 0
                  )}
                </BaSeParagraph>
              </span>
            </div>
          </div>
          {dataPlanning?.results?.nfcg <= 0 && (
            <span className="message-observation">
              <BaSeParagraph isThin>
                Não há necessidade de Formação de Capital de Giro
              </BaSeParagraph>
            </span>
          )}
        </>
      ) : (
        ""
      )}

      <div className="bar"></div>

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm();
        }}
      >
        {({ handleSubmit }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <BaSeInput
              inputMode="decimal"
              typeInput="tel"
              prefix="% "
              maxLength={8}
              inputStatus={validatePercentage(form.VGdQV) ? "invalid" : "valid"}
              value={form.VGdQV}
              onChange={(e) => {
                setForm({ ...form, VGdQV: e });
              }}
              onBlur={() => fnOnBlur()}
              size="medium"
              placeholder="%"
              label="Aumento geral das quantidades vendidas"
              width="100%"
              mask="decimal"
              showHelpButton
              helpButtonProps={popoverSold}
              isDisabled={dataPlanning?.is_finished}
            />
            <Textarea
              value={form.VGdQV_just}
              setValue={(e) => {
                setForm({ ...form, VGdQV_just: e });
              }}
              onBlur={() => fnOnBlur()}
              isDisabled={dataPlanning?.is_finished}
              placeholder="Descreva a justificativa para aumento geral das quantidades vendidas"
              label="Justificativa (opcional)"
            />

            <BaSeInput
              color="default"
              typeInput="tel"
              prefix="% "
              maxLength={8}
              inputStatus={validatePercentage(form.VGdPV) ? "invalid" : "valid"}
              value={form.VGdPV}
              onChange={(e) => {
                setForm({ ...form, VGdPV: e });
              }}
              onBlur={() => fnOnBlur()}
              size="medium"
              placeholder="%"
              label="Aumento geral de preços de venda"
              width="100%"
              mask="decimal"
              showHelpButton
              helpButtonProps={popoverPrices}
              isDisabled={dataPlanning?.is_finished}
            />

            <Textarea
              value={form.VGdPV_just}
              setValue={(e) => {
                setForm({ ...form, VGdPV_just: e });
              }}
              onBlur={() => fnOnBlur()}
              isDisabled={dataPlanning?.is_finished}
              placeholder="Descreva a justificativa para aumento geral das quantidades vendidas"
              label="Justificativa (opcional)"
            />

            <div className="bar"></div>

            <div className="second-tip-block">
              <TipBlock
                image={logoMonthlyCapacity}
                borderColor="linear-gradient(to top, #84F4BC, #40BBFF)"
              >
                <BaSeHeading6 color="#005EB8">
                  Capacidade Mensal de Pagamentos
                </BaSeHeading6>
              </TipBlock>
            </div>

            {Object.values(dataPlanning?.results?.fluxgraph).length ? (
              <div className="chart-area">
                <div className="wrapper-select">
                  <BaSeOptionPicker
                    label="Período da projeção"
                    width={286}
                    values={periods()}
                    enableSelectAll={false}
                    menuAlwaysIsOpen={false}
                    withFilterText={true}
                    emptyValueLabel="Selecione"
                    maxOptionsSelected={1}
                    initialSelectedValuesId={currentPeriod}
                    onChange={(values) => {
                      setCurrentPeriod(values.map((val) => val.id));
                    }}
                  />
                  {currentPeriod.length ? (
                    <button
                      className="btn-clear"
                      type="button"
                      onClick={() => setCurrentPeriod([])}
                    >
                      Limpar filtro
                    </button>
                  ) : null}
                </div>
                <div className="chart-area__data">
                  <MyChart
                    result={dataPlanning?.results}
                    period={currentPeriod}
                  />
                </div>
              </div>
            ) : (
              <div className="projected-flow">
                <BaSeParagraph color="#8B99A7">
                  Você ainda não tem nenhum Fluxo de Caixa Projetado
                </BaSeParagraph>

                <BaSeButton
                  buttonType="button"
                  color="#0E778B"
                  value="Criar Fluxo de Caixa Projetado"
                  onClick={() => handleSubmit()}
                />
              </div>
            )}

            <div className="bar"></div>

            <div className="goal">
              <img src={logoTrophy} alt="goal" />
              <div className="goal__input-area">
                <span style={{ display: "flex" }}>
                  <BaSeParagraph>
                    Estabeleça uma meta para cumprir o seu planejamento!
                  </BaSeParagraph>
                  <BaSePopupButton
                    title="Estabeleça uma meta para cumprir o seu planejamento!"
                    type="popover"
                    direction="left"
                    message={
                      <span dangerouslySetInnerHTML={{ __html: goalPlan }} />
                    }
                    alertSize={300}
                  />
                </span>

                <div className="date-picker">
                  <BaSeDatePicker
                    calendarType="days"
                    minDate={getDateTomorrow()}
                    startDate={form.goal_date}
                    onStartDateChange={(e) => setForm({...form, goal_date: e})}
                    placeholder="Selecione sua data"
                    label="Meta do planejamento"
                    isDisabled={dataPlanning?.is_finished}
                  />
                </div>
              </div>
            </div>

            <div className="submit-and-download">
              <button
                type="button"
                onClick={handleOnSubmit}
                className={
                  "btn-without-style finish-and-download " +
                  (dataPlanning?.is_finished ? "download" : "finish")
                }
              >
                {dataPlanning?.is_finished ? (
                  <BaSeIcon
                    color="#243342"
                    description="Download"
                    name="download-alt"
                    size={20}
                  />
                ) : (
                  ""
                )}
                <p>
                  {!dataPlanning?.is_finished
                    ? "Finalizar e Baixar Planejamento"
                    : "Baixar Planejamento"}
                </p>
              </button>
            </div>

            <span
              onClick={() => setShowModal(true)}
              className="technical-notes"
            >
              <BaSeParagraph color="#617385">Notas técnicas</BaSeParagraph>
            </span>

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep()}
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Results;
