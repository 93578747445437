import {validateCurrency} from "../../../services/validate";
import {BaSeButton, BaSeHeading5, BaSeInput} from "@base/react";
import {useState} from "react";
import {Form, Formik} from "formik";
import {convertObjectValuesToFloat, formatCurrency} from "../../../services/utils";

const TotalInvestment = ({dataPlanning, sendForm, backStep}) => {
  const [form, setForm] = useState({
    cgp: formatCurrency(dataPlanning?.data?.cgp) || 0,
    iif: formatCurrency(dataPlanning?.data?.iif) || 0,
  })

  const fnOnBlur = () => {
    submitForm(false, true)
  }
  const submitForm = (nextStep = true, fnBlur = false) => {
    sendForm({
      ...dataPlanning,
      data: {
        ...dataPlanning.data,
        ...convertObjectValuesToFloat(form)
      }
    }, nextStep, fnBlur)
  }

  return (
    <div className="step total-investment">
      <BaSeHeading5 color="#005EB8" fontFamily="Alegreya Sans" isBold className="mt-0">
        Investimento total
      </BaSeHeading5>

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm()
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <BaSeInput
              isDisabled={dataPlanning?.is_finished}
              color="default"
              inputMode="decimal"
              typeInput="tel"
              inputStatus={validateCurrency(form.iif) ? "invalid" : "valid"}
              value={form.iif}
              onChange={(e) => { setForm({...form, iif: e  })}}
              onBlur={() => fnOnBlur()}
              placeholder="R$"
              maxLength={22}
              prefix="R$ "
              mask="decimal"
              size="medium"
              label="Valor investimento fixo"
              width="100%"
              showHelpButton
              helpButtonProps={{
                direction: 'bottom',
                alertSize: 180,
                iconProps: {
                  color: "#243342",
                  size: 16
                },
                title: "Valor investimento fixo",
                message: "Informe o valor dos móveis, equipamentos, máquinas e instalações. Para novos\n" +
                  "negócios, insira o valor do investimento necessário para iniciar as operações e alcançar as metas de vendas mensais.",
                type: "popover"
              }}
            />

            <BaSeInput
              isDisabled={dataPlanning?.is_finished}
              color="default"
              inputMode="decimal"
              typeInput="tel"
              inputStatus={validateCurrency(form.cgp) ? "invalid" : "valid"}
              value={form.cgp}
              onChange={(e) => { setForm({...form, cgp: e  })}}
              onBlur={() => fnOnBlur()}
              placeholder="R$"
              maxLength={22}
              prefix="R$ "
              mask="decimal"
              size="medium"
              label="Valor Capital de Giro próprio "
              width="100%"
              showHelpButton
              helpButtonProps={{
                direction: 'bottom',
                alertSize: 200,
                iconProps: {
                  color: "#243342",
                  size: 16
                },
                title: "Valor Capital de Giro próprio ",
                message: "Informe o valor total disponível que a empresa possui para cobrir pagamentos e custos operacionais em momentos de baixa no caixa. Este é o investimento financeiro da empresa.",
                type: "popover"
              }}
            />

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep() }
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TotalInvestment