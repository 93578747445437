import "./index.scss";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { principal, validateLinkVideo } from "../../services/utils";
import { BaSeHeading5, BaSeIcon, BaSeParagraph } from "@base/react";
import moment from "moment/moment";
import ApiCMS from "../../services/cms";
import Loading from "../../components/loading";
import Breadcrump from "../../components/breadcrump";

const ApiPill = ApiCMS();

const PillContent = ({ companies }) => {
  const breadcrumb = [
    { label: "Início", href: "/" },
    { label: "Todas Pílulas", href: "/conteudo" },
    { label: "Conteúdo" },
  ];

  const { id } = useParams();
  const [pill, setPill] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPill = () => {
    setLoading(true);
    ApiPill.getPillById(id, principal(companies))
      .then((response) => {
        const { data } = response;
        setPill(data);
      })
      .catch((error) => {
        console.warn("ERROR: ", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPill();
  }, [id, principal(companies)]);

  return (
    <>
      {loading ? (
        <Loading fullPage />
      ) : (
        <div className="pill-container">
          <div className="container">
            <div className="pill-container__header">
              <Link className="back-page" to="/conteudo">
                <BaSeIcon
                  name="angle-left"
                  color="#fff"
                  description="Icon"
                  size={20}
                />
                <BaSeParagraph color="#fff">
                  Pílulas de Conhecimento
                </BaSeParagraph>
              </Link>
              <div className="breadcrump">
                <Breadcrump color="#fff" items={breadcrumb} separator=">" />
              </div>
              <BaSeHeading5 isBold color="#fff">
                {pill?.title}
              </BaSeHeading5>
              <BaSeParagraph color="#fff" isThin>
                Autor:{" "}
                {pill?.modified_by.toString().length < 2
                  ? "Sebrae"
                  : pill?.modified_by}{" "}
                | Data: {moment(pill?.created_at).format("DD/MM/YYYY")}
              </BaSeParagraph>
            </div>

            <div className="pill-container__body-mobile">
              {pill?.image && pill?.video ? (
                <>
                  <div className="image-container">
                    <img src={pill?.image} alt="Imagem em destaque" />
                  </div>
                  <div className="text-container">
                    <BaSeParagraph>{pill?.body}</BaSeParagraph>
                  </div>
                  <div className="media-container">
                    <iframe
                      src={validateLinkVideo(pill?.video)}
                      width="465px"
                      height="261.95px"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </>
              ) : pill?.image ? (
                <>
                  <div className="image-container">
                    <img src={pill?.image} alt="Imagem em destaque" />
                  </div>
                  <div className="text-container">
                    <BaSeParagraph>{pill?.body}</BaSeParagraph>
                  </div>
                </>
              ) : pill?.video ? (
                <>
                  <div
                    className="media-container"
                    style={{
                      float: "left",
                    }}
                  >
                    <iframe
                      src={validateLinkVideo(pill?.video)}
                      width="465px"
                      height="261.95px"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="text-container">
                    <BaSeParagraph>{pill?.body}</BaSeParagraph>
                  </div>
                </>
              ) : (
                <div className="text-container">
                  <BaSeParagraph>{pill?.body}</BaSeParagraph>
                </div>
              )}
            </div>
          </div>
          <div className="previousAndNextPage">
            <Link
              to={pill?.has_previous ? `/pilula/${pill.has_previous}` : ""}
              className={!pill?.has_previous ? "pill-disabled" : ""}
            >
              <BaSeIcon
                name="angle-left"
                color={!pill?.has_previous ? "#808080" : "#005eb8"}
                description="Icon"
                size={20}
              />
              <BaSeParagraph
                color={!pill?.has_previous ? "#808080" : "#005eb8"}
                isBold
              >
                Conteúdo anterior
              </BaSeParagraph>
            </Link>
            <Link
              to={pill?.has_next ? `/pilula/${pill.has_next}` : ""}
              className={!pill?.has_next ? "pill-disabled" : ""}
            >
              <BaSeParagraph
                color={!pill?.has_next ? "#808080" : "#005eb8"}
                isBold
              >
                Próximo conteúdo
              </BaSeParagraph>
              <BaSeIcon
                name="angle-right"
                color={!pill?.has_next ? "#808080" : "#005eb8"}
                description="Icon"
                size={20}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default PillContent;
