import Requests from "./requests";

const ApiCompaniesSizes = () => {
  const instance = Requests();

  const getCompaniesSizes = (cnpj) => {
    return instance.get(`/porte/`, {
      params: {
        cnpj,
      },
    });
  };

  return { getCompaniesSizes };
};

export default ApiCompaniesSizes;
