import {BaSeErrorPage} from "@base/react"

const Unauthorized = () => {
  return (
    <BaSeErrorPage
      error={`Infelizmente sua conta está impossibilitada de utilizar nossa ferramenta. Por favor, entre em contato conosco.`}
      title="Acesso negado"
      button={{
        leftIcon: 'device/phone-line',
        onClick: () => {window.open("https://sacnacional.sebrae.com.br/",'_blank')},
        value: 'Fale conosco'
      }}
    />
  )
}

export default Unauthorized
