import { validateCurrency } from "../../../services/validate";
import {
  BaSeButton,
  BaSeGrid,
  BaSeGridItem,
  BaSeHeading5,
  BaSeInput,
} from "@base/react";
import { useState } from "react";
import { Form, Formik } from "formik";
import TitleWithPopover from "../../../components/title-with-popover";
import {
  convertObjectValuesToFloat,
  formatCurrency,
} from "../../../services/utils";

const MonthlyAverage = ({ dataPlanning, sendForm, backStep }) => {
  const [form, setForm] = useState({
    cif: formatCurrency(dataPlanning?.data?.cif) || 0,
    cmp: formatCurrency(dataPlanning?.data?.cmp) || 0,
    cms: formatCurrency(dataPlanning?.data?.cms) || 0,
    rtp: formatCurrency(dataPlanning?.data?.rtp) || 0,
    rts: formatCurrency(dataPlanning?.data?.rts) || 0,
  });
  const fnOnBlur = () => {
    submitForm(false, true);
  };
  const submitForm = (nextStep = true, fnBlur = false) => {
    sendForm(
      {
        ...dataPlanning,
        data: {
          ...dataPlanning.data,
          ...convertObjectValuesToFloat(form),
        },
      },
      nextStep,
      fnBlur,
      true
    );
  };

  return (
    <div className="step monthly-average">
      <BaSeHeading5
        color="#005EB8"
        fontFamily="Alegreya Sans"
        isBold
        className="mt-0"
      >
        Média Mensal
      </BaSeHeading5>

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm();
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TitleWithPopover
              title="Receita total"
              messagePopover="Para negócios em funcionamento, insira o valor médio mensal das vendas dos últimos três meses. Se você está planejando um novo negócio, informe o valor da meta estabelecida para a receita mensal."
            />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  color="default"
                  inputMode="tel"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.rtp) ? "invalid" : "valid"}
                  value={form.rtp}
                  onChange={(e) => {
                    setForm({ ...form, rtp: e });
                  }}
                  onBlur={() => fnOnBlur()}
                  maxLength={22}
                  prefix="R$ "
                  mask="decimal"
                  size="medium"
                  label="Produtos"
                  width="100%"
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.rts) ? "invalid" : "valid"}
                  value={form.rts}
                  onChange={(e) => {
                    setForm({ ...form, rts: e });
                  }}
                  onBlur={() => fnOnBlur()}
                  placeholder="R$"
                  maxLength={22}
                  prefix="R$ "
                  mask="decimal"
                  size="medium"
                  label="Serviços"
                  width="100%"
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover
              title="Custo com mercadorias"
              messagePopover={[
                "Para negócios em funcionamento, informe o valor médio dos custos dos últimos três meses.",
                "\n\nCaso esteja planejando um novo negócio, insira o custo das mercadorias de acordo com a meta de vendas mensais estabelecida.",
              ]}
            />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.cmp) ? "invalid" : "valid"}
                  value={form.cmp}
                  onChange={(e) => {
                    setForm({ ...form, cmp: e });
                  }}
                  onBlur={() => fnOnBlur()}
                  placeholder="R$"
                  maxLength={22}
                  prefix="R$ "
                  mask="decimal"
                  size="medium"
                  label="Produtos"
                  width="100%"
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.cms) ? "invalid" : "valid"}
                  value={form.cms}
                  onChange={(e) => {
                    setForm({ ...form, cms: e });
                  }}
                  onBlur={() => fnOnBlur()}
                  placeholder="R$"
                  maxLength={22}
                  prefix="R$ "
                  mask="decimal"
                  size="medium"
                  label="Serviços"
                  width="100%"
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover
              title="Custos Indiretos Fixos"
              messagePopover="Informe a soma de todos os custos fixos mensais, como aluguel e outras despesas recorrentes."
            />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  color="default"
                  inputMode="decimal"
                  typeInput="tel"
                  inputStatus={validateCurrency(form.cif) ? "invalid" : "valid"}
                  value={form.cif}
                  onChange={(e) => {
                    setForm({ ...form, cif: e });
                  }}
                  onBlur={() => fnOnBlur()}
                  placeholder="R$"
                  maxLength={22}
                  prefix="R$ "
                  mask="decimal"
                  size="medium"
                  label="Valor"
                  width="100%"
                />
              </BaSeGridItem>
            </BaSeGrid>

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep()}
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MonthlyAverage;
