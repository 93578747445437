import { useState } from "react";
import { validatePercentage } from "../../../services/validate";
import { BaSeButton, BaSeGrid, BaSeGridItem, BaSeInput } from "@base/react";
import { Form, Formik } from "formik";
import TitleWithPopover from "../../../components/title-with-popover";
import {
  convertObjectValuesToFloat,
  formatCurrency,
} from "../../../services/utils";

const popoverRates = {
  direction: "right",
  alertSize: 180,
  iconProps: {
    color: "#243342",
    size: 16,
  },
  title: "% Taxas",
  message: "Taxas percentuais de cada item.",
  type: "popover",
};
const popoverRevenue = {
  direction: "left",
  alertSize: 180,
  iconProps: {
    color: "#243342",
    size: 16,
  },
  title: "% da Receita Total",
  message:
    "Informe o percentual das vendas ao qual cada taxa de custo variável se aplica. Por exemplo, se 50% das suas vendas são feitas no cartão de débito, insira 50% na linha correspondente.",
  type: "popover",
};

const VariableIndirectCosts = ({ dataPlanning, sendForm, backStep }) => {
  const structureData = {
    cc: {
      pdt: formatCurrency(dataPlanning?.data?.cc?.pdt) || 0,
      pdrt: formatCurrency(dataPlanning?.data?.cc?.pdrt) || 0,
    },
    ci: {
      pdt: formatCurrency(dataPlanning?.data?.ci?.pdt) || 0,
      pdrt: formatCurrency(dataPlanning?.data?.ci?.pdrt) || 0,
    },
    ou: {
      pdt: formatCurrency(dataPlanning?.data?.ou?.pdt) || 0,
      pdrt: formatCurrency(dataPlanning?.data?.ou?.pdrt) || 0,
    },
    tcc: {
      pdt: formatCurrency(dataPlanning?.data?.tcc?.pdt) || 0,
      pdrt: formatCurrency(dataPlanning?.data?.tcc?.pdrt) || 0,
    },
    tcd: {
      pdt: formatCurrency(dataPlanning?.data?.tcd?.pdt) || 0,
      pdrt: formatCurrency(dataPlanning?.data?.tcd?.pdrt) || 0,
    },
  };
  const [form, setForm] = useState(structureData);

  const handleChange = (newValue, field, type) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [type]: newValue,
      },
    }));
  };
  const fnOnBlur = () => {
    submitForm(false, true);
  };
  const submitForm = (nextStep = true, fnBlur = false) => {
    sendForm(
      {
        ...dataPlanning,
        data: {
          ...dataPlanning.data,
          ...convertObjectValuesToFloat(form),
        },
      },
      nextStep,
      fnBlur
    );
  };

  function handlePercentageChange(e, handleChange, key1, key2) {
    let numericValue = parseFloat(e.replace(",", "."));

    if (isNaN(numericValue)) numericValue = 0;

    if (numericValue > 100) numericValue = 100;

    const formattedValue = numericValue.toFixed(2).replace(".", ",");

    handleChange(formattedValue, key1, key2);
  }

  return (
    <div className="step variable-indirect-costs">
      <TitleWithPopover
        title="Custos Indiretos Variáveis"
        messagePopover="Estes são os custos que variam de acordo com as mudanças nas vendas, refletindo o impacto direto das vendas no seu negócio."
        size={5}
      />

      <Formik
        initialValues={form}
        onSubmit={() => {
          submitForm();
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TitleWithPopover
              title="Taxa de cartão de débito"
              smallTitle="(média)"
            />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.tcd.pdt) ? "invalid" : "valid"
                  }
                  value={form.tcd.pdt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "tcd", "pdt");
                    //Modelo antigo: handleChange(e, "tcd", "pdt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.tcd.pdrt) ? "invalid" : "valid"
                  }
                  value={form.tcd.pdrt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "tcd", "pdrt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover
              title="Taxa de cartão de crédito"
              smallTitle="(média)"
            />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.tcc.pdt) ? "invalid" : "valid"
                  }
                  value={form.tcc.pdt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "tcc", "pdt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.tcc.pdrt) ? "invalid" : "valid"
                  }
                  value={form.tcc.pdrt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "tcc", "pdrt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Custos com comissões" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.cc.pdt) ? "invalid" : "valid"
                  }
                  value={form.cc.pdt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "cc", "pdt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.cc.pdrt) ? "invalid" : "valid"
                  }
                  value={form.cc.pdrt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "cc", "pdrt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Custos com impostos" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.ci.pdt) ? "invalid" : "valid"
                  }
                  value={form.ci.pdt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "ci", "pdt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.ci.pdrt) ? "invalid" : "valid"
                  }
                  value={form.ci.pdrt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "ci", "pdrt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <TitleWithPopover title="Outros custos" />
            <BaSeGrid>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.ou.pdt) ? "invalid" : "valid"
                  }
                  value={form.ou.pdt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "ou", "pdt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% Taxas"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRates}
                />
              </BaSeGridItem>
              <BaSeGridItem size={6}>
                <BaSeInput
                  isDisabled={dataPlanning?.is_finished}
                  inputMode="decimal"
                  typeInput="tel"
                  prefix="% "
                  maxLength={8}
                  inputStatus={
                    validatePercentage(form.ou.pdrt) ? "invalid" : "valid"
                  }
                  value={form.ou.pdrt}
                  onChange={(e) => {
                    handlePercentageChange(e, handleChange, "ou", "pdrt");
                  }}
                  onBlur={() => fnOnBlur()}
                  size="medium"
                  placeholder="%"
                  label="% da Receita Total"
                  width="100%"
                  mask="decimal"
                  showHelpButton
                  helpButtonProps={popoverRevenue}
                />
              </BaSeGridItem>
            </BaSeGrid>

            <div className="planning__footer">
              <BaSeButton
                type="secondary"
                buttonType="button"
                onClick={() => backStep()}
                color="#005eb8"
                size="medium"
                value="Anterior"
                width="87px"
              />

              <BaSeButton
                buttonType="submit"
                size="medium"
                value="Próximo"
                width="87px"
                onClick={() => handleSubmit}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VariableIndirectCosts;
