import {BaSeIcon} from "@base/react";
import React from "react";
import './index.scss'

const MenuDropdown = ({data = [], sizeIcon = 18, colorIcon = "#8B99A7", isOpen = false, click}) => {

  return data.length ? (
    <div className="menu-dropdown">
      <BaSeIcon
        onClick={() => click()}
        color={colorIcon}
        description="Menu"
        name="system/more-2-fill"
        size={sizeIcon}
      />
      {
        isOpen ? (
          <ul>
            {
              data.map((item, k) => (
                <li key={k} onClick={() => item.callback()} title={item.name}>
                  <div className="link">
                    {item.icon}
                    <p>{item.name}</p>
                  </div>
                </li>
              ))
            }
          </ul>
        ) : ""
      }
    </div>
  ) : ""
}

export default MenuDropdown