import { BaSeButton } from "@base/react";
import img from "../../assets/images/login.png";
import { useEffect, useState } from "react";
import "./index.scss";
import axios from "axios";
import { getToken } from "../../services/utils";
import ApiAuth from "../../services/auth";
import Loading from "../../components/loading";

const baseUrl = process.env.REACT_APP_AMEI;
const realm = process.env.REACT_APP_REALM;
const client_id = process.env.REACT_APP_CLIENT_ID;
const redirect_uri = `${window.location.protocol}//${window.location.host}/`;

const Auth = ({ account, setAccount }) => {
  const [loading, setLoading] = useState(false);

  const userAgent = navigator.userAgent;

  const isMobile =
    /Mobile|Android|iP(hone|od|ad)|IEMobile|BlackBerry|Opera Mini/i.test(
      userAgent
    );
  const device = isMobile ? "Mobile" : "Desktop";

  let browser = "Unknown";
  if (userAgent.indexOf("Firefox") > -1) {
    browser = "Firefox";
  } else if (
    userAgent.indexOf("Chrome") > -1 &&
    userAgent.indexOf("Edg") === -1
  ) {
    browser = "Chrome";
  } else if (
    userAgent.indexOf("Safari") > -1 &&
    userAgent.indexOf("Chrome") === -1
  ) {
    browser = "Safari";
  } else if (userAgent.indexOf("Edg") > -1) {
    browser = "Edge";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browser = "Opera";
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident") > -1
  ) {
    browser = "Internet Explorer";
  }

  const buttonTemplate = () => (
    <BaSeButton
      buttonType="button"
      onClick={() => {
        redirectToKeycloak();
      }}
      size="medium"
      value="Acessar"
      width="87px"
    />
  );
  const getCompanies = () => {
    ApiAuth()
      .GetCompanies()
      .then((res) => {
        let { data } = res;

        const newData = data.company_list.map((item) => {
          return {
            ...item,
            isActive: item.principal ? true : false,
          };
        });
        localStorage.setItem("companies", JSON.stringify(newData));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        window.location.href = "/";
      });
  };
  const registerLogin = () => {
    ApiAuth()
      .RegisterLogin(device, browser)
      .then(() => {
        localStorage.setItem("registered", "true");
        getCompanies();
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        if (error.status === 400) {
          window.location.href = "/";
        } else if (error.status === 406) {
          localStorage.setItem("unauthorized", true);
          setAccount({ ...account, unauthorized: true });
        }
      });
  };
  const generateRandomState = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          // eslint-disable-next-line
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const redirectToKeycloak = () => {
    const state = generateRandomState();
    const nonce = generateRandomState();
    const urlParams = {
      client_id,
      redirect_uri,
      response_mode: "fragment",
      response_type: "code",
      scope: "openid",
      nonce,
      state,
    };
    const connectionURI = new URL(
      `${baseUrl}/realms/${realm}/protocol/openid-connect/auth`
    );
    for (const key of Object.keys(urlParams)) {
      connectionURI.searchParams.append(key, urlParams[key]);
    }

    window.location.href = connectionURI;
  };
  const setLocalStorage = (code) => {
    try {
      const tokenURI = `${baseUrl}/realms/${realm}/protocol/openid-connect/token`;
      const body = new URLSearchParams();
      body.append("client_id", client_id);
      body.append("redirect_uri", redirect_uri);
      body.append("grant_type", "authorization_code");
      body.append("code", code);
      setLoading(true);
      axios
        .post(tokenURI, body, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((r) => {
          const { access_token, id_token, refresh_token, session_state } =
            r.data;
          localStorage.setItem("token", access_token);
          localStorage.setItem("id_token", id_token);
          localStorage.setItem("refresh_token", refresh_token);
          localStorage.setItem("session_state", session_state);
          localStorage.setItem("onboarding", JSON.parse("true"));

          if (!localStorage.getItem("registered")) {
            registerLogin()
          }
        })
        .catch((err) => {
          console.error(err);
        })
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    const token = getToken();
    const params = new URLSearchParams(window.location.hash.split("#")[1]);
    const code = params.get("code");

    if (code && !token) {
      setLocalStorage(code);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="auth">
      {loading ? (
        <Loading fullPage={true} />
      ) : (
        <div className="presentation">
          <div className="container">
            <div className="steps">
              <div className="step">
                <figure>
                  <img src={img} alt="Calculadora" />
                </figure>
                <div className="text">
                  <h6>Planejadora Sebrae</h6>
                  <h2>Planejadora Financeira Empresarial</h2>
                  <div className="navigate-desktop">{buttonTemplate()}</div>
                </div>
              </div>
            </div>
            <div className="presentation__footer">{buttonTemplate()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
