import "./index.scss";
import Acronyms from "../acronyms";
import { BaSeHeading4 } from "@base/react";
import { useState, useContext, useEffect } from "react";
import { CompaniesContext } from "../../context/companies";
import { useLocation } from "react-router-dom";

const FloatingMenu = () => {
  const [open, setOpen] = useState(false);
  const { companies, toggleCompanies } = useContext(CompaniesContext);
  const { pathname } = useLocation();

  const [actives, inactive] = companies.reduce(
    ([active, inac], item) => {
      !item.isActive ? inac.push(item) : active.push(item);
      return [active, inac];
    },
    [[], []],
  );

  return (
    <div className={`profile ${open ? "floating" : ""}`}>
      <div onClick={() => (pathname !== "/" ? setOpen(!open) : null)}>
        {companies.length && companies.some((company) => company.isActive) ? (
          companies.map(
            (item, k) =>
              item.isActive && <Acronyms key={k} nameUser={item.name} />,
          )
        ) : (
          <Acronyms nameUser="Pessoa Fisica" />
        )}
      </div>

      {pathname !== "/" && companies.length ? (
        <div className="floating-menu">
          <div className="close" onClick={() => setOpen(false)}>
            x
          </div>
          <ul>
            {actives.map((item, k) => (
              <li key={k}>
                <>
                  <Acronyms nameUser={`${item.name}`} />
                  <div key={k} className="title">
                    <BaSeHeading4>
                      <span>
                        {item.name.length > 25
                          ? `${item.name.slice(0, 25)}...`
                          : item.name}
                      </span>
                    </BaSeHeading4>
                    <p>CNPJ: {item.cnpj}</p>
                  </div>
                </>
              </li>
            ))}
            {inactive.map((item, k) => (
              <li
                key={k}
                onClick={() => {
                  toggleCompanies(item.cnpj);
                }}
              >
                <>
                  <Acronyms nameUser={`${item.name}`} />
                  <div key={k} className="title">
                    <BaSeHeading4>
                      <span>
                        {item.name.length > 25
                          ? `${item.name.slice(0, 25)}...`
                          : item.name}
                      </span>
                    </BaSeHeading4>
                    <p>CNPJ: {item.cnpj}</p>
                  </div>
                </>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default FloatingMenu;
